.dfooter {
 
}
a {
  text-decoration: none;
  color: #333;
}
ul {
  list-style: none;
}
footer {
  /* background-color: #333; */
  background: linear-gradient(90deg, #100f0f 0%, #444141 43.75%, #eb0808 100%);
  color: #fff;
  text-align: center;
}
