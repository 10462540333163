/*! CSS Used from: https://spruko.com/demo/hosting/hostma/dist/assets/libs/bootstrap/css/bootstrap.min.css */
*,
::after,
::before {
  box-sizing: border-box;
}
p {
  margin-top: 0;
  margin-bottom: 1rem;
}
a {
  color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 1));
  text-decoration: underline;
}
a:hover {
  --bs-link-color-rgb: var(--bs-link-hover-color-rgb);
}
img {
  vertical-align: middle;
}
.img-fluid {
  max-width: 100%;
  height: auto;
}
.container {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}
@media (min-width: 1400px) {
  .container {
    max-width: 1320px;
  }
}
.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
}
.row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}
@media (min-width: 992px) {
  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
}
.btn {
  --bs-btn-padding-x: 0.75rem;
  --bs-btn-padding-y: 0.375rem;
  --bs-btn-font-size: 1rem;
  --bs-btn-font-weight: 400;
  --bs-btn-line-height: 1.5;
  --bs-btn-color: var(--bs-body-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-width: var(--bs-border-width);
  --bs-btn-border-color: transparent;
  --bs-btn-border-radius: 0.375rem;
  --bs-btn-hover-border-color: transparent;
  --bs-btn-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(0, 0, 0, 0.075);
  --bs-btn-disabled-opacity: 0.65;
  --bs-btn-focus-box-shadow: 0 0 0 0.25rem
    rgba(var(--bs-btn-focus-shadow-rgb), 0.5);
  display: inline-block;
  padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
  font-family: var(--bs-btn-font-family);
  font-size: var(--bs-btn-font-size);
  font-weight: var(--bs-btn-font-weight);
  line-height: var(--bs-btn-line-height);
  color: var(--bs-btn-color);
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
  border-radius: var(--bs-btn-border-radius);
  background-color: var(--bs-btn-bg);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
}
:not(.btn-check) + .btn:active {
  color: var(--bs-btn-active-color);
  background-color: var(--bs-btn-active-bg);
  border-color: var(--bs-btn-active-border-color);
}
.btn:disabled {
  color: var(--bs-btn-disabled-color);
  pointer-events: none;
  background-color: var(--bs-btn-disabled-bg);
  border-color: var(--bs-btn-disabled-border-color);
  opacity: var(--bs-btn-disabled-opacity);
}
.btn-secondary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #6c757d;
  --bs-btn-border-color: #6c757d;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #5c636a;
  --bs-btn-hover-border-color: #565e64;
  --bs-btn-focus-shadow-rgb: 130, 138, 145;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #565e64;
  --bs-btn-active-border-color: #51585e;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #6c757d;
  --bs-btn-disabled-border-color: #6c757d;
}
.btn-lg {
  --bs-btn-padding-y: 0.5rem;
  --bs-btn-padding-x: 1rem;
  --bs-btn-font-size: 1.25rem;
  --bs-btn-border-radius: 0.5rem;
}
.bottom-0 {
  bottom: 0 !important;
}
.align-items-center {
  align-items: center !important;
}
.mt-5 {
  margin-top: 3rem !important;
}
.me-1 {
  margin-right: 0.25rem !important;
}
.mb-3 {
  margin-bottom: 1rem !important;
}
.mb-4 {
  margin-bottom: 1.5rem !important;
}
.ms-1 {
  margin-left: 0.25rem !important;
}
.text-start {
  text-align: left !important;
}
.text-center {
  text-align: center !important;
}
.text-white {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
}
@media (min-width: 992px) {
  .mt-lg-0 {
    margin-top: 0 !important;
  }
}
/*! CSS Used from: https://spruko.com/demo/hosting/hostma/dist/assets/css/styles.css */
.btn {
  padding: 0.5rem 1rem;
  font-size: 0.85rem;
  font-weight: 500;
  border-radius: 0.3rem;
}
.btn:active {
  color: #fff;
}
.btn-secondary {
  background-color: #f78326 !important;
  border-color: rgb(var(--secondary-rgb)) !important;
}
.btn-secondary:hover,
.btn-secondary:focus:hover {
  background-color: rgba(var(--secondary-rgb), 0.9);
  border-color: rgb(var(--secondary-rgb));
}
.btn-secondary:focus {
  background-color: rgb(var(--secondary-rgb));
  border-color: rgb(var(--secondary-rgb));
  outline: 0;
  box-shadow: none;
}
.btn-secondary:active:focus {
  box-shadow: 0 0 0 0.2rem rgba(var(--secondary-rgb), 0.5);
}
.btn-secondary:active {
  background-color: rgb(var(--secondary-rgb));
  border-color: rgb(var(--secondary-rgb));
}
.btn-lg {
  padding: 0.69rem 1.2rem;
  font-size: 1rem;
}
*::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  -webkit-transition: all ease 0.3s;
  transition: all ease 0.3s;
}
::-webkit-scrollbar-thumb {
  background: var(--primary02);
}
*:hover::-webkit-scrollbar-thumb {
  background: #adb5bd;
}
section {
  position: relative;
}
.section {
  padding: 70px 0px;
}
.blob-bg-sec {
  background:  linear-gradient(90deg, #100f0f 0%, #444141 43.75%, #eb0808 100%);
  color: #fff;
  position: relative;
  overflow: hidden;
  z-index: 1;
}
.blob-bg-sec::before {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  inset-block-start: 0;
  background-image: url("https://spruko.com/demo/hosting/hostma/dist/assets/images/backgrounds/7.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  opacity: 0.08;
  transition: transform ease 0.3s;
  z-index: -1;
}
.patterns-5 {
  inset-inline-start: -3%;
  inset-block-end: -7%;
  z-index: -1;
  position: absolute;
  opacity: 0.3;
  width: 200px;
  height: 200px;
}
.patterns-7 {
  inset-inline-end: 0;
  inset-block-start: 0;
  z-index: -1;
  position: absolute;
  opacity: 0.1;
  width: 200px;
  height: 200px;
}
.heading-section {
  text-align: center;
  -webkit-margin-after: 3rem;
  margin-block-end: 3rem;
}
.heading-title {
  font-size: 2rem;
  -webkit-margin-after: 0.5rem;
  margin-block-end: 0.5rem;
  font-family: "Urbanist", sans-serif;
  color: var(--color-dark);
  font-weight: 700;
  line-height: 1.2;
}
.heading-description {
  font-size: 1rem;
  -webkit-margin-after: 0;
  margin-block-end: 0;
  color: var(--default-text-color);
  opacity: 0.8;
}
.tx-20 {
  font-size: 1.25rem;
}
a {
  text-decoration: none;
  color: var(--default-text-color);
}
a:hover {
  --bs-text-opacity: 1;
  opacity: 1;
  color: var(--primary-color);
}
.fw-500 {
  font-weight: 500;
}
.op-1 {
  opacity: 0.1;
}
.op-8 {
  opacity: 0.8;
}
.banner-pd-3 {
  padding: 50px 0px;
}
.height-inherit {
  height: inherit;
}
.transform-rotate-180 {
  transform: rotate(180deg);
}
/*! CSS Used from: Embedded */
* {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: -moz-none;
  -ms-user-select: none;
  user-select: none;
}
/*! CSS Used fontfaces */
@font-face {
  font-family: "Urbanist";
  font-style: normal;
  font-weight: 500;
  font-display: block;
  src: url(https://fonts.gstatic.com/s/urbanist/v15/L0x-DF02iFML4hGCyMqrbS10ig.woff2)
    format("woff2");
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Urbanist";
  font-style: normal;
  font-weight: 500;
  font-display: block;
  src: url(https://fonts.gstatic.com/s/urbanist/v15/L0x-DF02iFML4hGCyMqlbS0.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191,
    U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Urbanist";
  font-style: normal;
  font-weight: 600;
  font-display: block;
  src: url(https://fonts.gstatic.com/s/urbanist/v15/L0x-DF02iFML4hGCyMqrbS10ig.woff2)
    format("woff2");
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Urbanist";
  font-style: normal;
  font-weight: 600;
  font-display: block;
  src: url(https://fonts.gstatic.com/s/urbanist/v15/L0x-DF02iFML4hGCyMqlbS0.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191,
    U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
