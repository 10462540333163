/*! CSS Used from: https://html.hixstudio.net/seomy-prv/seomy/assets/css/bootstrap.css */
*,
::after,
::before {
  box-sizing: border-box;
}
.dalazu_team h4 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}
.dalazu_team h4 {
  font-size: calc(1.275rem + 0.3vw);
}
@media (min-width: 1200px) {
  .dalazu_team  h4 {
    font-size: 1.5rem;
  }
}
.dalazu_team p {
  margin-top: 0;
  margin-bottom: 1rem;
}
.dalazu_team a {
  color: #0d6efd;
  text-decoration: underline;
}
.dalazu_team a:hover {
  color: #0a58ca;
}
.dalazu_team img {
  vertical-align: middle;
}
.dalazu_team .container {
  width: 100%;
  padding-right: var(--bs-gutter-x, 15px);
  padding-left: var(--bs-gutter-x, 15px);
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 576px) {
  .dalazu_team  .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .dalazu_team .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .dalazu_team  .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .dalazu_team  .container {
    max-width: 1140px;
  }
}
@media (min-width: 1400px) {
  .dalazu_team  .container {
    max-width: 1200px;
  }
}
.dalazu_team .row {
  --bs-gutter-x: 30px;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(var(--bs-gutter-y) * -1);
  margin-right: calc(var(--bs-gutter-x) * -0.5);
  margin-left: calc(var(--bs-gutter-x) * -0.5);
}
.dalazu_team .row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}
@media (min-width: 768px) {
  .dalazu_team .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }
}
@media (min-width: 992px) {
  .dalazu_team .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .dalazu_team .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }
}
.dalazu_team .text-center {
  text-align: center !important;
}
/*! CSS Used from: https://html.hixstudio.net/seomy-prv/seomy/assets/css/font-awesome-pro.css */
.fa-brands {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: var(--fa-display, inline-block);
  font-style: normal;
  font-variant: normal;
  line-height: 1;
  text-rendering: auto;
}
.fa-brands {
  font-family: "Font Awesome 6 Brands";
  font-weight: 400;
}
.fa-facebook-f:before {
  content: "\f39e";
}
.fa-linkedin-in:before {
  content: "\f0e1";
}
.fa-twitter:before {
  content: "\f099";
}
/*! CSS Used from: https://html.hixstudio.net/seomy-prv/seomy/assets/css/spacing.css */
.mb-10 {
  margin-bottom: 10px;
}
.mb-25 {
  margin-bottom: 25px;
}
.mb-35 {
  margin-bottom: 35px;
}
.mb-40 {
  margin-bottom: 40px;
}
.mb-70 {
  margin-bottom: 70px;
}
.pb-30 {
  padding-bottom: 30px;
}
.pb-50 {
  padding-bottom: 50px;
}
/*! CSS Used from: https://html.hixstudio.net/seomy-prv/seomy/assets/css/main.css */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
a {
  text-decoration: none;
}
h4 {
  font-family: var(--tp-ff-heading);
  color: var(--tp-heading-primary);
  margin-top: 0px;
  font-weight: 700;
  line-height: 1.2;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
h4 {
  font-size: 20px;
}
p {
  font-family: var(--tp-ff-p);
  font-size: 16px;
  font-weight: 400;
  color: var(--tp-text-body);
  margin-bottom: 15px;
  line-height: 26px;
}
a,
p,
h4 {
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
a:focus {
  text-decoration: none;
  outline: none;
}
a:focus,
a:hover {
  color: inherit;
  text-decoration: none;
}
a {
  color: inherit;
  outline: none;
  border: none;
  background: transparent;
}
::selection {
  background: var(--tp-theme-primary);
  color: var(--tp-common-white);
  text-shadow: none;
}
*::placeholder {
  color: var(--tp-common-black);
  font-size: var(--tp-fz-body);
  opacity: 1;
}
.gx-9 {
  --bs-gutter-x: 58px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .gx-9 {
    --bs-gutter-x: 25px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .gx-9 {
    --bs-gutter-x: 60px;
  }
}
@media (max-width: 767px) {
  .gx-9 {
    --bs-gutter-x: 30px;
  }
}
.dalazu_team img {
  max-width: 100%;
  height: 300px;
}
.tp-btn {
  display: inline-block;
  font-size: 16px;
  font-weight: 600;
  color: var(--tp-common-white);
  background: var(--tp-theme-primary);
  font-family: var(--tp-ff-jakarta);
  text-align: center;
  padding: 12px 33px;
  position: relative;
  z-index: 1;
  overflow: hidden;
  border-radius: 4px;
}
.tp-btn:hover {
  color: var(--tp-common-white);
  background-color: #3653eb;
  border-color: var(--tp-theme-primary);
}
.tp-btn:focus {
  color: var(--tp-common-white);
}
.section-3 {
  position: relative;
  z-index: 2;
}
.section-3-title {
  font-family: var(--tp-ff-jakarta);
  font-weight: 700;
  font-size: 60px;
  line-height: 1.09;
  letter-spacing: -0.01em;
  color: var(--tp-common-11);
}
@media (max-width: 767px) {
  .section-3-title {
    font-size: 40px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-3-title {
    font-size: 50px;
  }
}
@media (max-width: 767px) {
  .section-3-title br {
    display: none;
  }
}
.section-3 p {
  font-family: var(--tp-ff-jakarta);
  font-size: 18px;
  color: var(--tp-grey-8);
  opacity: 0.8;
}
.team-5-thumb {
  position: relative;
  background: #f5bce3;
  border-radius: 200px 4px 200px 200px;
  text-align: center;
  /* padding-top: 35px; */
  overflow: hidden;
}
.team-5-thumb img {
  display: inline-block;
 
}
.team-5-title {
  font-family: var(--tp-ff-jakarta);
  font-weight: 600;
  font-size: 22px;
  color: var(--tp-common-11);
}
.team-5-title a:hover {
  color: var(--tp-theme-primary);
}
.team-5-content p {
  font-family: var(--tp-ff-jakarta);
  font-size: 14px;
  line-height: 12px;
  color: var(--tp-grey-8);
}
.team-5-social {
  position: absolute;
  top: 20px;
  right: 20px;
  background-color: #fff;
  z-index: 2;
  width: 40px;
  height: 40px;
  border-radius: 50px;
  cursor: pointer;
  text-align: center;
  transition: all 0.5s ease;
  overflow: hidden;
}
.team-5-social:hover {
  height: 130px;
}
.team-5-social:hover a {
  opacity: 1;
}
.team-5-social:hover .icon {
  height: 32px;
}
.team-5-social:hover .icon::after {
  content: "\f068";
}
.team-5-social .icon {
  width: 40px;
  height: 40px;
  border-radius: 50px;
  line-height: 40px;
  text-align: center;
  display: inline-block;
  color: #55585b;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.team-5-social .icon::after {
  content: "\f067";
  font-weight: 900;
  font-family: "Font Awesome 5 pro";
}
.team-5-social a {
  background-color: #fff;
  width: 40px;
  height: 26px;
  border-radius: 50px;
  line-height: 30px;
  text-align: center;
  font-size: 13px;
  color: #a3a5a7;
  display: inline-block;
  opacity: 0;
}
.team-5-social a:hover {
  color: #ff622d;
}
.team-5-social a:nth-of-type(2) {
  transition-delay: 0.1s;
}
.team-5-social a:nth-of-type(3) {
  transition-delay: 0.2s;
}
.team-5-item-2 .team-5-thumb {
  background: #f1e1a8;
}
.team-5-item-3 .team-5-thumb {
  background: #b7f8c5;
}
.team-5-item-4 .team-5-thumb {
  background: #fbc7b7;
}
.team-inner-all .tp-btn {
  padding: 9px 37px;
}
/*! CSS Used fontfaces */
@font-face {
  font-family: "Font Awesome 6 Brands";
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url(https://html.hixstudio.net/seomy-prv/seomy/assets/fonts/fa-brands-400.woff2)
      format("woff2"),
    url(https://html.hixstudio.net/seomy-prv/seomy/assets/fonts/fa-brands-400.ttf)
      format("truetype");
}
