/*! CSS Used from: https://html.hixstudio.net/seomy-prv/seomy/assets/css/bootstrap.css */
*,
::after,
::before {
  box-sizing: border-box;
}
.main-header ul {
  padding-left: 2rem;
}
.main-header ul {
  margin-top: 0;
  margin-bottom: 1rem;
}
.main-header a {
  color: #0d6efd;
  text-decoration: underline;
}
.main-header a:hover {
  color: #0a58ca;
}
.main-header img {
  vertical-align: middle;
}
.main-header button {
  border-radius: 0;
}
.main-header button:focus:not(:focus-visible) {
  outline: 0;
}
.main-header button {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
.main-header button {
  text-transform: none;
}
.main-header button {
  -webkit-appearance: button;
}
.main-header button:not(:disabled) {
  cursor: pointer;
}
.main-header .row {
  --bs-gutter-x: 30px;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(var(--bs-gutter-y) * -1);
  margin-right: calc(var(--bs-gutter-x) * -0.5);
  margin-left: calc(var(--bs-gutter-x) * -0.5);
}
.main-header .row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}
.main-header .col {
  flex: 1 0 0%;
}
.main-header .row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}
@media (min-width: 768px) {
  .main-header .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
}
@media (min-width: 1200px) {
  .main-header .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
}
.main-header .col-6 {
  flex: 0 0 auto;
  width: 50%;
}
@media (min-width: 576px) {
  .main-header .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .main-header .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
}
@media (min-width: 992px) {
  .main-header .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }
}
@media (min-width: 1200px) {
  .main-header .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .main-header .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .main-header .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
}
.main-header .d-none {
  display: none !important;
}
.main-header .align-items-center {
  align-items: center !important;
}
.main-header .mb-5 {
  margin-bottom: 3rem !important;
}
.main-header .text-end {
  text-align: right !important;
}
@media (min-width: 1200px) {
  .main-header .d-xl-block {
    display: block !important;
  }
  .main-header .d-xl-none {
    display: none !important;
  }
}
/*! CSS Used from: https://html.hixstudio.net/seomy-prv/seomy/assets/css/font-awesome-pro.css */
.main-header .fa-solid {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: var(--fa-display, inline-block);
  font-style: normal;
  font-variant: normal;
  line-height: 1;
  text-rendering: auto;
}
.main-header .fa-bars:before {
  content: "\f0c9";
}
.main-header .fa-solid {
  font-family: "Font Awesome 6 Pro";
  font-weight: 900;
}
/*! CSS Used from: https://html.hixstudio.net/seomy-prv/seomy/assets/css/spacing.css */
.main-header .mb-5 {
  margin-bottom: 5px !important;
}
.main-header .ml-20 {
  margin-left: 20px;
}
/*! CSS Used from: https://html.hixstudio.net/seomy-prv/seomy/assets/css/main.css */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
a {
  text-decoration: none;
}
ul {
  margin: 0px;
  padding: 0px;
}
a,
button,
li {
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
a:focus {
  text-decoration: none;
  outline: none;
}
a:focus,
a:hover {
  color: inherit;
  text-decoration: none;
}
a,
button {
  color: inherit;
  outline: none;
  border: none;
  background: transparent;
}
button:hover {
  cursor: pointer;
}
button:focus {
  outline: 0;
}
::selection {
  background: var(--tp-theme-primary);
  color: var(--tp-common-white);
  text-shadow: none;
}
*::placeholder {
  color: var(--tp-common-black);
  font-size: var(--tp-fz-body);
  opacity: 1;
}
@media (min-width: 1400px) {
  .custom-container {
    padding-right: var(--bs-gutter-x, 15px);
    padding-left: var(--bs-gutter-x, 15px);
    margin-right: auto;
    margin-left: auto;
    max-width: 1230px;
  }
}
.main-header .custom-container {
  padding-right: var(--bs-gutter-x, 15px);
  padding-left: var(--bs-gutter-x, 15px);
  margin-right: auto;
  margin-left: auto;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .main-header .custom-container {
    width: 1170px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-header .custom-container {
    width: 1000px;
  }
}
.main-header .gx-6 {
  --bs-gutter-x: 18px;
}
.main-header img {
  max-width: 100%;
  height: auto;
}
.main-header .menu-btn {
  display: inline-block;
  font-size: 16px;
  font-weight: 600;
  color: var(--tp-common-white);
  background: var(--tp-theme-primary);
  font-family: var(--tp-ff-jakarta);
  text-align: center;
  padding: 12px 33px;
  position: relative;
  z-index: 1;
  overflow: hidden;
  border-radius: 4px;
}
.main-header .menu-btn:hover {
  color: var(--tp-common-white);
  background-color: #3653eb;
  border-color: var(--tp-theme-primary);
}
.menu-btn:focus {
  color: var(--tp-common-white);
}
.main-header .homemenu-btn {
  line-height: 1;
}
.main-header .homemenu-btn .menu-btn {
  padding: 12px 24px 14px 24px;
  font-size: 15px;
  width: 140px;
}
.main-header .blue-btn {
  font-family: var(--tp-ff-jakarta);
  font-weight: 600;
  font-size: 16px;
  line-height: 1;
  color: var(--tp-common-white);
  background-color: var(--tp-theme-blue);
  display: inline-block;
  padding: 14px 32px;
  border: 2px solid var(--tp-theme-blue);
  border-radius: 30px;
  text-align: center;
}
.main-header .blue-btn:hover {
  background-color: var(--tp-common-white);
  color: var(--tp-theme-blue);
}
.main-header .offcanvas-btn {
  line-height: 1;
}
.main-header .offcanvas-open-btn {
  line-height: 1;
}
.main-header .tplogo__area a img {
  max-width: 105px;
}
.main-header .tpmenu__area ul li {
  display: inline-block;
  list-style: none;
  margin: 0 16px;
  position: relative;
}
@media only screen and (min-width: 1600px) and (max-width: 1700px),
  only screen and (min-width: 1200px) and (max-width: 1399px) {
  .main-header .tpmenu__area ul li {
    margin: 0 17px;
  }
}
@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .main-header .tpmenu__area ul li {
    margin: 0 14px;
  }
}
.main-header .tpmenu__area ul li:hover.has-dropdown a::after {
  color: var(--tp-theme-primary);
}
.main-header .tpmenu__area ul li:hover .submenu {
  opacity: 1;
  visibility: visible;
  transform: scale(1);
}
.main-header .tpmenu__area ul li:hover > a {
  color: var(--tp-theme-primary);
}
.main-header .tpmenu__area ul li.has-dropdown > a {
  position: relative;
}
.main-header .tpmenu__area ul li.has-mega-menu {
  position: static;
}
.main-header .tpmenu__area ul li.has-dropdown > a {
  position: relative;
}

.main-header .tpmenu__area ul li .submenu {
  position: absolute;
  left: 0;
  right: 0;
  top: 101.3%;
  min-width: 200px;
  background: #ffffff;
  box-shadow: 0px 20px 30px rgba(1, 3, 13, 0.12);
  border-radius: 6px;
  margin: 0;
  transform: scale(1, 0);
  transform-origin: 0 0;
  transition: all 0.2s linear 0s;
  --webkit-transition: all 0.2s linear 0s;
  padding: 12px 0;
  display: block;
  visibility: hidden;
  opacity: 0;
  z-index: 9;
  border-top: 2px solid transparent;
}
.main-header .tpmenu__area ul li > a {
  font-size: 16px;
  color: var(--tp-text-1);
  font-weight: 500;
  letter-spacing: 0.02em;
  padding: 26px 0;
  display: inline-block;
  text-decoration: none;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.main-header .tpmenu__area ul li > a:hover {
  color: var(--tp-theme-primary);
}
.main-header .tpmenu__area > nav > ul > li .tp-mega-menu {
  position: absolute;
  top: 101.3%;
  left: 0;
  right: 0;
  width: 100%;
  background-color: var(--tp-common-white);
  z-index: 9;
  padding: 38px 20px 38px 40px;
  transform-origin: top;
  box-shadow: 0px 20px 30px rgba(1, 3, 13, 0.12);
  border-radius: 6px;
  border-top: 2px solid transparent;
}
.main-header .tpmenu__area > nav > ul > li .tp-mega-menu.has-homemenu {
  padding: 20px 20px 0;
  box-shadow: 0 20px 30px rgba(1, 15, 28, 0.1);
}
.main-header .tpmenu__area ul li .has-homemenu .homemenu {
  padding: 0px 10px;
  position: relative;
  margin-bottom: 20px;
}
.main-header .tpmenu__area ul li .has-homemenu .homemenu:hover .homemenu-btn {
  opacity: 1;
  visibility: visible;
  transform: translate(-50%, -60%);
}
.main-header
  .tpmenu__area
  ul
  li
  .has-homemenu
  .homemenu:hover
  .homemenu-thumb::before {
  background-color: rgba(1, 15, 28, 0.3);
}
.main-header .tpmenu__area ul li .has-homemenu .homemenu:hover .demo-name span {
  color: var(--tp-theme-primary);
}
.main-header .homemenu-thumb {
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.1);
}
.main-header .homemenu-thumb::before {
  position: absolute;
  content: "";
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  pointer-events: none;
}
.main-header .homemenu-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -30%);
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.main-header .homemenu {
  padding: 7px 7px;
  position: relative;
  text-align: center;
}
.main-header .homemenu:hover .homemenu-btn {
  opacity: 1;
  visibility: visible;
  transform: translate(-50%, -60%);
}
.main-header .homemenu:hover .homemenu-thumb::before {
  background-color: rgba(1, 15, 28, 0.3);
}
.main-header .homemenu:hover .demo-name span {
  color: var(--tp-theme-primary);
}
.main-header .demo-name span {
  font-family: var(--tp-ff-jakarta);
  font-weight: 500;
  color: #0e1331;
  font-size: 15px;
  color: #0e1331;
  display: inline-block;
  margin-top: 8px;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.main-header .offcanvas-open-btn i {
  font-size: 20px;
  font-weight: 600;
}
.main-header .header-btn-4 .blue-btn:hover {
  background-color: #6e51e6;
  color: var(--tp-common-white);
  border: 2px solid #6e51e6;
}
.main-header .header-bg-4 {
  background: rgba(255, 255, 255, 0.04);
  border: 1px solid rgba(255, 255, 255, 0.5);
  box-shadow: 0px 10px 30px rgba(1, 15, 28, 0.1);
  backdrop-filter: blur(8px);
  border-radius: 100px;
  padding: 0 15px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px),
  only screen and (min-width: 768px) and (max-width: 991px),
  (max-width: 767px) {
  .main-header .header-bg-4 {
    padding: 15px 15px;
  }
}
@media (max-width: 767px) {
  .main-header .header-bg-4 {
    margin-top: 15px;
  }
}
.main-header .tptransparent__header-4 .tpmenu__area ul li {
  margin: 0 37px 0 0;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .main-header .tptransparent__header-4 .tpmenu__area ul li {
    margin: 0 24px 0 0;
  }
}
.main-header .tptransparent__header-4 .tpmenu__area ul li:last-child {
  margin-right: 0 0 0 0;
}
.main-header .tptransparent__header-4 .tpmenu__area ul li > a {
  font-family: var(--tp-ff-jakarta);
  font-size: 16px;
  color: var(--tp-text-1);
  font-weight: 500;
  letter-spacing: 0.02em;
  padding: 21px 0;
}
.main-header .tptransparent__header-4 .tpmenu__area ul li:hover > a {
  color: var(--tp-theme-blue);
}
.main-header
  .tptransparent__header-4
  .tpmenu__area
  ul
  li:hover.has-dropdown
  a::after {
  color: var(--tp-theme-blue);
}
/*! CSS Used fontfaces */
@font-face {
  font-family: "Font Awesome 6 Pro";
  font-style: normal;
  font-weight: 300;
  font-display: block;
  src: url(https://html.hixstudio.net/seomy-prv/seomy/assets/fonts/fa-light-300.woff2)
      format("woff2"),
    url(https://html.hixstudio.net/seomy-prv/seomy/assets/fonts/fa-light-300.ttf)
      format("truetype");
}
@font-face {
  font-family: "Font Awesome 6 Pro";
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url(https://html.hixstudio.net/seomy-prv/seomy/assets/fonts/fa-regular-400.woff2)
      format("woff2"),
    url(https://html.hixstudio.net/seomy-prv/seomy/assets/fonts/fa-regular-400.ttf)
      format("truetype");
}
@font-face {
  font-family: "Font Awesome 6 Pro";
  font-style: normal;
  font-weight: 900;
  font-display: block;
  src: url(https://html.hixstudio.net/seomy-prv/seomy/assets/fonts/fa-solid-900.woff2)
      format("woff2"),
    url(https://html.hixstudio.net/seomy-prv/seomy/assets/fonts/fa-solid-900.ttf)
      format("truetype");
}
@font-face {
  font-family: "Font Awesome 6 Pro";
  font-style: normal;
  font-weight: 100;
  font-display: block;
  src: url(https://html.hixstudio.net/seomy-prv/seomy/assets/fonts/fa-thin-100.woff2)
      format("woff2"),
    url(https://html.hixstudio.net/seomy-prv/seomy/assets/fonts/fa-thin-100.ttf)
      format("truetype");
}
