/*! CSS Used from: https://spruko.com/demo/hosting/hostma/demo/lib/bootstrap/css/bootstrap.min.css */
*,
::after,
::before {
  box-sizing: border-box;
}
.dalazu h1,
.dalazu h5 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}
.dalazu h1 {
  font-size: calc(1.375rem + 1.5vw);
}
@media (min-width: 1200px) {
  .dalazu h1 {
    font-size: 2.5rem;
  }
}
.dalazu h5 {
  font-size: 1.25rem;
}
.dalazu p {
  margin-top: 0;
  margin-bottom: 1rem;
}
.dalazu img {
  vertical-align: middle;
}
.dalazu .img-fluid {
  max-width: 100%;
  height: auto;
}
.dalazu .container {
  width: 100%;
  padding-right: var(--bs-gutter-x, 0.75rem);
  padding-left: var(--bs-gutter-x, 0.75rem);
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 576px) {
  .dalazu .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .dalazu .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .dalazu .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .dalazu .container {
    max-width: 1140px;
  }
}
@media (min-width: 1400px) {
  .dalazu .container {
    max-width: 1320px;
  }
}
.dalazu .row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
}
.dalazu .row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}
@media (min-width: 992px) {
  .dalazu .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
}
.dalazu .card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}
.dalazu .card-body {
  flex: 1 1 auto;
  padding: 1rem 1rem;
}
.dalazu .d-flex {
  display: flex !important;
}
.dalazu .shadow-none {
  box-shadow: none !important;
}
.dalazu .flex-grow-1 {
  flex-grow: 1 !important;
}
.align-items-start {
  align-items: flex-start !important;
}
.align-items-center {
  align-items: center !important;
}
.mt-5 {
  margin-top: 3rem !important;
}
.mb-0 {
  margin-bottom: 0 !important;
}
.mb-2 {
  margin-bottom: 0.5rem !important;
}
.text-center {
  text-align: center !important;
}
.text-white {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
}
.bg-transparent {
  --bs-bg-opacity: 1;
  background-color: transparent !important;
}
/*! CSS Used from: https://spruko.com/demo/hosting/hostma/demo/lib/feather/feather.css */
.fe {
  font-family: "feather" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.fe-layout:before {
  content: "\e97e";
}
.fe-settings:before {
  content: "\e9c2";
}
.fe-sun:before {
  content: "\e9d5";
}
.fe-toggle-left:before {
  content: "\e9df";
}
/*! CSS Used from: https://spruko.com/demo/hosting/hostma/demo/css/demo.css */
*,
*::before,
*::after {
  box-sizing: border-box;
}
.dalazu section {
  display: block;
}
.dalazu h1,
.dalazu h5 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}
.dalazu p {
  margin-top: 0;
  margin-bottom: 1rem;
}
.dalazu img {
  vertical-align: middle;
  border-style: none;
}
.dalazu h1,
.dalazu h5 {
  margin-bottom: 0.5rem;
  line-height: 1.2;
  font-family: "Urbanist", sans-serif;
  color: #03123e;
  font-weight: 600;
}
.dalazu h1 {
  font-size: 2.1875rem;
}
.dalazu h5 {
  font-size: 1.09375rem;
}
.dalazu .img-fluid {
  max-width: 100%;
  height: auto;
}
.dalazu .container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 576px) {
  .dalazu .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .dalazu .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .dalazu .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .dalazu .container {
    max-width: 1140px;
  }
}
.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}
.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid #ced4da;
  border-radius: 3px;
}
.card-body {
  flex: 1 1 auto;
  padding: 1.25rem;
}
.bg-transparent {
  background-color: transparent !important;
}
.d-flex {
  display: flex !important;
}
.shadow-none {
  box-shadow: none !important;
}
.mb-0 {
  margin-bottom: 0 !important;
}
.mb-2 {
  margin-bottom: 0.5rem !important;
}
.mt-5 {
  margin-top: 3rem !important;
}
.text-center {
  text-align: center !important;
}
.text-white {
  color: #fff !important;
}
.card {
  border-radius: 0;
}
.card-body {
  padding: 20px;
}
.demo-screen-demo {
  padding: 30px 0;
  background-color:#ffffff
}
@media (min-width: 992px) {
  .demo-screen-demo {
    padding: 60px 0;
  }
}
.demo-screen-demo h1 {
  text-align: center;
  color: #031938;
  font-size: 24px;
}
.dalazu .heading-subtitle {
  position: relative;
  display: inline-block;
  -webkit-margin-after: 0.5rem;
  margin-block-end: 0.5rem;
}
.dalazu .heading-subtitle::before,
.dalazu .heading-subtitle::after {
  content: "";
  position: absolute;
  width: 20px;
  height: 2px;
  inset-inline-start: -30px;
  background-color: #f78326;
  inset-block-start: 50%;
}
.dalazu .heading-subtitle::after {
  inset-inline-end: -30px;
  inset-inline-start: inherit;
}
@media (min-width: 992px) {
  .demo-screen-demo h1 {
    margin-top: 0;
    text-align: center;
    font-size: 2rem;
    -webkit-margin-after: 0.5rem;
    margin-block-end: 0.5rem;
    font-family: "Urbanist", sans-serif;
    font-weight: 700;
    line-height: 1.2;
  }
  .demo-screen-demo p {
    margin-top: 0;
    font-size: 15px;
    line-height: 22px;
    font-family: inherit;
  }
}
.bg-transparent {
  background-color: transparent;
}
.op-8 {
  opacity: 0.8;
}
.tx-primary {
  color: #1457e6;
}
.tx-16 {
  font-size: 16px;
}
.card {
  background-color: #fff;
  position: relative;
  margin-bottom: 30px;
  border-radius: 8px;
  border: 1px solid #eaedf1;
  box-shadow: 0 0.15rem 1.75rem 0 rgba(196, 205, 224, 0.2);
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
}
.card:hover {
  box-shadow: 0px 6px 18px 4px rgba(6, 10, 48, 0.08);
  -webkit-transform: scale(1.02);
  transform: scale(1.02);
}
.card .card-body {
  padding: 1.5rem 1.5rem;
}
.bg-primary-gradient1 {
  background: linear-gradient(to right, #1457e6 0%, rgb(38, 143, 230) 100%);
}
.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}
@media (min-width: 1200px) {
  .demo-screen .container {
    max-width: 1300px;
  }
}
#features h1 {
  margin: 0;
  margin-bottom: 5px;
}
.reveal {
  transform: translateY(80px);
  opacity: 0;
  transition: all 0.3s ease;
}
.reveal.active {
  opacity: 1;
  transform: translateY(0px);
}
.br-style {
  border-start-start-radius: 50% 10%;
  border-start-end-radius: 20% 40%;
  border-end-end-radius: 50% 10%;
  border-end-start-radius: 20% 40%;
}
.avatar {
  position: relative;
  height: 2.625rem;
  width: 2.625rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 auto;
  line-height: 0;
}
.bg-secondary-gradient {
  background: linear-gradient(
    to right,
    rgb(247, 131, 38) 0%,
    rgba(247, 131, 38, 0.5) 100%
  );
}
.bg-success-gradient {
  background: linear-gradient(
    to right,
    rgb(23, 202, 104) 0%,
    rgba(23, 202, 104, 0.5) 100%
  );
}
.bg-danger-gradient {
  background: linear-gradient(
    to right,
    rgb(236, 95, 103) 0%,
    rgba(236, 95, 103, 0.5) 100%
  );
}
/*! CSS Used keyframes */
@keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(20px);
    -ms-transform: translateY(20px);
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
}
/*! CSS Used fontfaces */
@font-face {
  font-family: "feather";
  src: url("https://spruko.com/demo/hosting/hostma/demo/lib/feather/fonts/feather/feather-webfont.eot?t=1501841394106");
  src: url("https://spruko.com/demo/hosting/hostma/demo/lib/feather/fonts/feather/feather-webfont.eot?t=1501841394106#iefix")
      format("embedded-opentype"),
    url("https://spruko.com/demo/hosting/hostma/demo/lib/feather/fonts/feather/feather-webfont.ttf?t=1501841394106")
      format("truetype"),
    url("https://spruko.com/demo/hosting/hostma/demo/lib/feather/fonts/feather/feather-webfont.svg?t=1501841394106#feather")
      format("svg");
}
@font-face {
  font-family: "Urbanist";
  font-style: normal;
  font-weight: 500;
  font-display: block;
  src: url(https://fonts.gstatic.com/s/urbanist/v15/L0x-DF02iFML4hGCyMqrbS10ig.woff2)
    format("woff2");
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Urbanist";
  font-style: normal;
  font-weight: 500;
  font-display: block;
  src: url(https://fonts.gstatic.com/s/urbanist/v15/L0x-DF02iFML4hGCyMqlbS0.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191,
    U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Urbanist";
  font-style: normal;
  font-weight: 600;
  font-display: block;
  src: url(https://fonts.gstatic.com/s/urbanist/v15/L0x-DF02iFML4hGCyMqrbS10ig.woff2)
    format("woff2");
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Urbanist";
  font-style: normal;
  font-weight: 600;
  font-display: block;
  src: url(https://fonts.gstatic.com/s/urbanist/v15/L0x-DF02iFML4hGCyMqlbS0.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191,
    U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
