/*! CSS Used from: https://spruko.com/demo/hosting/hostma/dist/assets/libs/bootstrap/css/bootstrap.min.css */
*,
::after,
::before {
  box-sizing: border-box;
}
img {
  vertical-align: middle;
}
.img-fluid {
  max-width: 100%;
  height: auto;
}
.dclients .container {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 576px) {
  .dclients .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .dclients .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .dclients .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .dclients .container {
    max-width: 1140px;
  }
}
@media (min-width: 1400px) {
  .dclients .container {
    max-width: 1320px;
  }
}
.dclients .row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
}
.dclients .row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}
@media (min-width: 576px) {
  .dclients .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }
}
@media (min-width: 768px) {
  .dclients .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
}
@media (min-width: 992px) {
  .dclients .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }
}
@media (min-width: 1200px) {
  .dclients .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
}
.dclients .card {
  --bs-card-spacer-y: 1rem;
  --bs-card-spacer-x: 1rem;
  --bs-card-title-spacer-y: 0.5rem;
  --bs-card-border-width: var(--bs-border-width);
  --bs-card-border-color: var(--bs-border-color-translucent);
  --bs-card-border-radius: var(--bs-border-radius);
  --bs-card-inner-border-radius: calc(
    var(--bs-border-radius) - (var(--bs-border-width))
  );
  --bs-card-cap-padding-y: 0.5rem;
  --bs-card-cap-padding-x: 1rem;
  --bs-card-cap-bg: rgba(var(--bs-body-color-rgb), 0.03);
  --bs-card-bg: var(--bs-body-bg);
  --bs-card-img-overlay-padding: 1rem;
  --bs-card-group-margin: 0.75rem;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  height: var(--bs-card-height);
  color: var(--bs-body-color);
  word-wrap: break-word;
  background-color: var(--bs-card-bg);
  background-clip: border-box;
  border: var(--bs-card-border-width) solid var(--bs-card-border-color);
  border-radius: var(--bs-card-border-radius);
}
.dclients .card-body {
  flex: 1 1 auto;
  padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
  color: var(--bs-card-color);
}
.dclients .justify-content-center {
  justify-content: center !important;
}
.dclients .align-items-center {
  align-items: center !important;
}
.dclients .fw-semibold {
  font-weight: 600 !important;
}
.text-center {
  text-align: center !important;
}
/*! CSS Used from: https://spruko.com/demo/hosting/hostma/dist/assets/css/styles.css */
.dclients .card {
  -webkit-margin-after: 1.5rem;
  margin-block-end: 1.5rem;
  border: 0px solid var(--border);
  border-radius: 0.625rem;
  box-shadow: var(--shadow);
  width: 100%;
  position: relative;
  background-color: var(--default-body-bg-color);
}
.dclients .card .tool-img-white {
  display: none;
}
.dclients .card-body {
  padding: 1.5rem;
  border: 1px solid gray;
  border-radius: 10px;
}
.dclients .feature-card-15 {
  transition-duration: 0.3s;
}
.dclients .feature-card-15:hover {
  box-shadow: 0px 6px 18px 4px rgba(6, 10, 48, 0.08);
  transform: translateY(10px);
}
*::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  -webkit-transition: all ease 0.3s;
  transition: all ease 0.3s;
}
::-webkit-scrollbar-thumb {
  background: var(--primary02);
}
*:hover::-webkit-scrollbar-thumb {
  background: #adb5bd;
}
.dclients .heading-section {
  text-align: center;
  -webkit-margin-after: 3rem;
  margin-block-end: 3rem;
}
.dclients .heading-title {
  font-size: 2rem;
  -webkit-margin-after: 0.5rem;
  margin-block-end: 0.5rem;
  font-family: "Urbanist", sans-serif;
  color: var(--color-dark);
  font-weight: 700;
  line-height: 1.2;
}
.dclients .heading-subtitle {
  position: relative;
  display: inline-block;
  -webkit-margin-after: 0.5rem;
  margin-block-end: 0.5rem;
}
.dclients .heading-subtitle::before,
.dclients .heading-subtitle::after {
  content: "";
  position: absolute;
  width: 20px;
  height: 2px;
  inset-inline-start: -30px;
  background-color: rgb(var(--secondary-rgb));
  inset-block-start: 50%;
}
.dclients .heading-subtitle::after {
  inset-inline-end: -30px;
  inset-inline-start: inherit;
}
.dclients .heading-description {
  font-size: 1rem;
  -webkit-margin-after: 0;
  margin-block-end: 0;
  color: var(--default-text-color);
  opacity: 0.8;
}
.dclients .tx-16 {
  font-size: 1rem;
}
.dclients .tx-primary {
  color: #1457e6;
}
/*! CSS Used from: Embedded */
* {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: -moz-none;
  -ms-user-select: none;
  user-select: none;
}
/*! CSS Used fontfaces */
@font-face {
  font-family: "Urbanist";
  font-style: normal;
  font-weight: 500;
  font-display: block;
  src: url(https://fonts.gstatic.com/s/urbanist/v15/L0x-DF02iFML4hGCyMqrbS10ig.woff2)
    format("woff2");
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Urbanist";
  font-style: normal;
  font-weight: 500;
  font-display: block;
  src: url(https://fonts.gstatic.com/s/urbanist/v15/L0x-DF02iFML4hGCyMqlbS0.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191,
    U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Urbanist";
  font-style: normal;
  font-weight: 600;
  font-display: block;
  src: url(https://fonts.gstatic.com/s/urbanist/v15/L0x-DF02iFML4hGCyMqrbS10ig.woff2)
    format("woff2");
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Urbanist";
  font-style: normal;
  font-weight: 600;
  font-display: block;
  src: url(https://fonts.gstatic.com/s/urbanist/v15/L0x-DF02iFML4hGCyMqlbS0.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191,
    U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
