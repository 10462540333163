/*! CSS Used from: https://colorlib.com/etc/cf/ContactFrom_v1/vendor/bootstrap/css/bootstrap.min.css */
@media print {
  *,
  ::after,
  ::before {
    text-shadow: none !important;
    box-shadow: none !important;
  }
  img {
    page-break-inside: avoid;
  }
}
*,
::after,
::before {
  box-sizing: inherit;
}
img {
  vertical-align: middle;
  border-style: none;
}
button,
input,
textarea {
  -ms-touch-action: manipulation;
  touch-action: manipulation;
}
button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}
button,
input,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
button,
input {
  overflow: visible;
}
button {
  text-transform: none;
}
button {
  -webkit-appearance: button;
}
textarea {
  overflow: auto;
  resize: vertical;
}
/*! CSS Used from: https://colorlib.com/etc/cf/ContactFrom_v1/fonts/font-awesome-4.7.0/css/font-awesome.min.css */
.fa {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.fa-long-arrow-right:before {
  content: "\f178";
}
/*! CSS Used from: https://colorlib.com/etc/cf/ContactFrom_v1/css/main.css */
* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}
input {
  outline: none;
  border: none;
}
textarea {
  outline: none;
  border: none;
}
textarea:focus,
input:focus {
  border-color: transparent !important;
}
button {
  outline: none !important;
  border: none;
  background: transparent;
}
button:hover {
  cursor: pointer;
}
.contact1 {
  width: 100%;
  min-height: 100%;
  padding: 15px;
  background: #ffffff;
  background: -webkit-linear-gradient(left, #0072ff, #00c6ff);
  background: -o-linear-gradient(left, #0072ff, #00c6ff);
  background: -moz-linear-gradient(left, #0072ff, #00c6ff);
  background: linear-gradient(left, #0072ff, #00c6ff);
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.container-contact1 {
  width: 1163px;
  background: #fff;
  border-radius: 10px;
  overflow: hidden;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 90px 130px 88px 148px;
}
.contact1-pic {
  width: 296px;
}
.contact1-pic img {
  max-width: 100%;
}
.contact1-form {
  width: 390px;
}
.contact1-form-title {
  display: block;
  font-family: Montserrat-ExtraBold;
  font-size: 24px;
  color: #333333;
  line-height: 1.2;
  text-align: center;
  padding-bottom: 44px;
}
input.input1 {
  height: 50px;
  border-radius: 25px;
  padding: 0 30px;
}
input.input1 + .shadow-input1 {
  border-radius: 25px;
}
textarea.input1 {
  min-height: 150px;
  border-radius: 25px;
  padding: 12px 30px;
}
textarea.input1 + .shadow-input1 {
  border-radius: 25px;
}
.wrap-input1 {
  position: relative;
  width: 100%;
  z-index: 1;
  margin-bottom: 20px;
}
.input1 {
  display: block;
  width: 100%;
  background: #e6e6e6;
  font-family: Montserrat-Bold;
  font-size: 15px;
  line-height: 1.5;
  color: #666666;
}
.shadow-input1 {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  box-shadow: 0px 0px 0px 0px;
  color: rgba(87, 184, 70, 0.5);
}
.input1:focus + .shadow-input1 {
  -webkit-animation: anim-shadow 0.5s ease-in-out forwards;
  animation: anim-shadow 0.5s ease-in-out forwards;
}
.container-contact1-form-btn {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.contact1-form-btn {
  min-width: 193px;
  height: 50px;
  border-radius: 25px;
  background: #57b846;
  font-family: Montserrat-Bold;
  font-size: 15px;
  line-height: 1.5;
  color: #fff;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 25px;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
}
.contact1-form-btn i {
  margin-left: 7px;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
}
.contact1-form-btn:hover {
  background: #333333;
}
.contact1-form-btn:hover i {
  -webkit-transform: translateX(10px);
  -moz-transform: translateX(10px);
  -ms-transform: translateX(10px);
  -o-transform: translateX(10px);
  transform: translateX(10px);
}
@media (max-width: 1200px) {
  .contact1-pic {
    width: 33.5%;
  }
  .contact1-form {
    width: 44%;
  }
}
@media (max-width: 992px) {
  .container-contact1 {
    padding: 90px 80px 88px 90px;
  }
  .contact1-pic {
    width: 35%;
  }
  .contact1-form {
    width: 55%;
  }
}
@media (max-width: 768px) {
  .container-contact1 {
    padding: 90px 80px 88px 80px;
  }
  .contact1-pic {
    display: none;
  }
  .contact1-form {
    width: 100%;
  }
}
@media (max-width: 576px) {
  .container-contact1 {
    padding: 90px 15px 88px 15px;
  }
}
.validate-input {
  position: relative;
}
/*! CSS Used keyframes */
@-webkit-keyframes anim-shadow {
  to {
    box-shadow: 0px 0px 80px 30px;
    opacity: 0;
  }
}
@keyframes anim-shadow {
  to {
    box-shadow: 0px 0px 80px 30px;
    opacity: 0;
  }
}
/*! CSS Used fontfaces */
@font-face {
  font-family: Montserrat-ExtraBold;
  src: url("https://colorlib.com/etc/cf/ContactFrom_v1/fonts/montserrat/Montserrat-ExtraBold.ttf");
}
@font-face {
  font-family: Montserrat-Bold;
  src: url("https://colorlib.com/etc/cf/ContactFrom_v1/fonts/montserrat/Montserrat-Bold.ttf");
}
