/*! CSS Used from: https://martex.vercel.app/_next/static/css/3f475461ef079320.css */
.dp_bg h2,
.dp_bg h5 {
  font-family: Plus Jakarta Sans, sans-serif;
  line-height: 1.35;
  font-weight: 700;
}
.dp_bg a {
  transition: all 0.3s ease-in-out;
}
.dp_bg a:hover {
  color: #444;
}
.dp_bg .btn {
  font-size: 1.185rem;
  padding: 0.95rem 2rem;
}
.dp_bg .btn:focus {
  color: #fff;
  box-shadow: none;
}
.dp_bg .fbox-txt h5 {
  font-size: 1.425rem;
  margin-bottom: 10px;
}
.dp_bg .fbox-txt p {
  font-size: 1.2rem;
  padding: 0 4%;
  margin-bottom: 0;
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .dp_bg .btn {
    font-size: 1.1rem;
    padding: 0.9rem 1.8rem;
  }
  .dp_bg .fbox-txt h5 {
    font-size: 1.375rem;
    margin-bottom: 10px;
  }
  .dp_bg .fbox-txt p {
    font-size: 1.125rem;
    padding: 0 4%;
    margin-bottom: 0;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .dp_bg .btn {
    font-size: 1.0625rem;
    padding: 0.85rem 1.7rem;
  }
  .dp_bg .fbox-txt h5 {
    font-size: 1.5rem;
    margin-bottom: 10px;
  }
  .dp_bg .fbox-txt p {
    font-size: 1.1875rem;
    padding: 0 13%;
    margin-bottom: 0;
  }
}
@media (min-width: 480px) and (max-width: 767.98px) {
  .dp_bg .btn {
    font-size: 1rem;
    padding: 0.825rem 1.7rem;
  }
  .dp_bg .fbox-txt h5 {
    font-size: 1.425rem;
    margin-bottom: 10px;
  }
  .dp_bg .fbox-txt p {
    font-size: 1.0625rem;
    padding: 0 10%;
    margin-bottom: 0;
  }
}
@media (min-width: 414px) and (max-width: 479.98px) {
  .dp_bg .btn {
    font-size: 0.935rem;
    padding: 0.75rem 1.6rem;
  }
  .dp_bg .fbox-txt h5 {
    font-size: 1.35rem;
    margin-bottom: 10px;
  }
  .dp_bg .fbox-txt p {
    font-size: 1.0625rem;
    padding: 0;
    margin-bottom: 0;
  }
}
@media (min-width: 390px) and (max-width: 413.98px) {
  .dp_bg .btn {
    font-size: 0.935rem;
    padding: 0.75rem 1.6rem;
  }
  .dp_bg .fbox-txt h5 {
    font-size: 1.35rem;
    margin-bottom: 10px;
  }
  .dp_bg .fbox-txt p {
    font-size: 1.0625rem;
    padding: 0;
    margin-bottom: 0;
  }
}
@media (min-width: 321px) and (max-width: 389.98px) {
  .dp_bg .btn {
    font-size: 0.935rem;
    padding: 0.75rem 1.6rem;
  }
  .dp_bg .fbox-txt h5 {
    font-size: 1.3rem;
    margin-bottom: 10px;
  }
  .dp_bg .fbox-txt p {
    font-size: 1rem;
    padding: 0;
    margin-bottom: 0;
  }
}
@media (max-width: 320.95px) {
  .dp_bg .btn {
    font-size: 0.935rem;
    padding: 0.75rem 1.6rem;
  }
  .dp_bg .fbox-txt h5 {
    font-size: 1.3rem;
    margin-bottom: 10px;
  }
  .dp_bg .fbox-txt p {
    font-size: 0.9rem;
    padding: 0 3%;
    margin-bottom: 0;
  }
}
*,
:after,
:before {
  box-sizing: border-box;
}
.dp_bg h2,
.dp_bg h5 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
  color: var(--bs-heading-color);
}
.dp_bg h2 {
  font-size: calc(1.325rem + 0.9vw);
}
@media (min-width: 1200px) {
  .dp_bg h2 {
    font-size: 2rem;
  }
}
.dp_bg h5 {
  font-size: 1.25rem;
}
.dp_bg p {
  margin-top: 0;
  margin-bottom: 1rem;
}
.dp_bg ul {
  padding-left: 2rem;
}
.dp_bg ul {
  margin-top: 0;
  margin-bottom: 1rem;
}
.dp_bg a {
  color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 1));
  text-decoration: underline;
}
.dp_bg a:hover {
  --bs-link-color-rgb: var(--bs-link-hover-color-rgb);
}
.dp_bg svg {
  vertical-align: middle;
}
.dp_bg .container {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 576px) {
  .dp_bg .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .dp_bg .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .dp_bg .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .dp_bg .container {
    max-width: 1140px;
  }
}
@media (min-width: 1400px) {
  .dp_bg .container {
    max-width: 1320px;
  }
}
.dp_bg .row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
}
.dp_bg .row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}
@media (min-width: 768px) {
  .dp_bg .col-md-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .dp_bg .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .dp_bg .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
}
.dp_bg .btn {
  --bs-btn-padding-x: 0.75rem;
  --bs-btn-padding-y: 0.375rem;
  --bs-btn-font-size: 1rem;
  --bs-btn-font-weight: 400;
  --bs-btn-line-height: 1.5;
  --bs-btn-color: var(--bs-body-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-width: var(--bs-border-width);
  --bs-btn-border-color: transparent;
  --bs-btn-border-radius: var(--bs-border-radius);
  --bs-btn-hover-border-color: transparent;
  --bs-btn-box-shadow: inset 0 1px 0 hsla(0, 0%, 100%, 0.15),
    0 1px 1px rgba(0, 0, 0, 0.075);
  --bs-btn-disabled-opacity: 0.65;
  --bs-btn-focus-box-shadow: 0 0 0 0.25rem
    rgba(var(--bs-btn-focus-shadow-rgb), 0.5);
  display: inline-block;
  padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
  font-family: var(--bs-btn-font-family);
  font-size: var(--bs-btn-font-size);
  font-weight: var(--bs-btn-font-weight);
  line-height: var(--bs-btn-line-height);
  color: var(--bs-btn-color);
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
  border-radius: var(--bs-btn-border-radius);
  background-color: var(--bs-btn-bg);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .dp_bg .btn {
    transition: none;
  }
}
.dp_bg .btn:hover {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
}
:not(.btn-check) + .btn:active {
  color: var(--bs-btn-active-color);
  background-color: var(--bs-btn-active-bg);
  border-color: var(--bs-btn-active-border-color);
}
.dp_bg .btn:disabled {
  color: var(--bs-btn-disabled-color);
  pointer-events: none;
  background-color: var(--bs-btn-disabled-bg);
  border-color: var(--bs-btn-disabled-border-color);
  opacity: var(--bs-btn-disabled-opacity);
}
.dp_bg .btn-sm {
  --bs-btn-padding-y: 0.25rem;
  --bs-btn-padding-x: 0.5rem;
  --bs-btn-font-size: 0.875rem;
  --bs-btn-border-radius: var(--bs-border-radius-sm);
}
.dp_bg .d-flex {
  display: flex !important;
}
.dp_bg .align-items-center {
  align-items: center !important;
}
.dp_bg .mb-0 {
  margin-bottom: 0 !important;
}
.dp_bg span[class^="flaticon-"]:before {
  font-family: flaticon !important;
  font-style: normal;
  font-weight: 400 !important;
  font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  font-size: 20px;
  line-height: 1 !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.dp_bg .flaticon-idea:before {
  content: "\f158";
}
.dp_bg .flaticon-graphics:before {
  content: "\f160";
}
.dp_bg .flaticon-graphic:before {
  content: "\f173";
}
.dp_bg .flaticon-search-engine-1:before {
  content: "\f194";
}
.dp_bg .fadeInLeft {
  animation-name: fadeInLeft;
}
.dp_bg .fadeInRight {
  animation-name: fadeInRight;
}
.dp_bg .container,
section {
  position: relative !important;
  z-index: 3;
}
.dp_bg .block-shadow {
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.08);
}
.dp_bg .r-04 {
  border-radius: 4px;
}
.dp_bg .r-12 {
  border-radius: 12px;
}
.dp_bg .pt-100 {
  padding-top: 100px;
}
.dp_bg .mb-30 {
  margin-bottom: 30px;
}
.bg--white-100 {
  background-color: #fff;
}
.dp_bg .shape--white-500:after {
  background-color: #f2f4f8;
}
.dp_bg h2,
.dp_bg h5 {
  color: #353f4f;
  font-weight: 600;
  letter-spacing: 0;
  margin-bottom: 0;
}
.dp_bg .font--jakarta h2,
.dp_bg .font--jakarta h5 {
  font-family: Plus Jakarta Sans, sans-serif;
  line-height: 1.35;
}
.dp_bg .font--jakarta h2 {
  line-height: 1.25;
}
.dp_bg p {
  font-size: 1rem;
}
.dp_bg .s-19 {
  font-size: 1.1875rem;
}
.dp_bg .s-46 {
  font-size: 2.875rem;
}
.dp_bg .w-700 {
  font-weight: 700;
}
.dp_bg a {
  color: #6c757d;
  transition: all 0.4s ease-in-out;
}
.dp_bg a,
.dp_bg a:hover {
  text-decoration: none;
}
.dp_bg a:hover {
  color: #353f4f !important;
}
.dp_bg a:focus {
  outline: none;
  text-decoration: none;
}
.dp_bg ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.dp_bg ul.simple-list {
  list-style: disc;
  margin-left: 15px;
}
.dp_bg .btn {
  background-color: transparent;
  font-size: 1rem;
  line-height: 1;
  font-weight: 500;
  padding: 0.9rem 1.8rem;
  border: 2px solid transparent;
  transition: all 0.4s ease-in-out;
}
.dp_bg .btn.btn-sm {
  font-size: 0.975rem;
  padding: 0.7rem 1.5rem;
}
.dp_bg .btn--tra-black {
  color: #353f4f !important;
  background-color: transparent !important;
  border-color: #353f4f !important;
}
.dp_bg .btn:focus {
  color: #fff;
  box-shadow: none;
}
.dp_bg .btn--tra-black:focus {
  color: #353f4f;
  box-shadow: none;
}
.dp_bg .ico-50 [class*="flaticon-"]:after,
.dp_bg .ico-50 [class*="flaticon-"]:before {
  font-size: 3.125rem;
}
.dp_bg .shape-ico {
  position: relative;
  display: inline-block;
  margin: 0 auto;
}
.dp_bg .shape-ico span {
  position: relative;
  z-index: 2;
}
.dp_bg .shape-ico svg {
  position: absolute;
  z-index: 1;
}
.dp_bg .ico-50 .shape-ico svg {
  width: 130px;
  height: 130px;
  top: -35px;
  left: calc(50% - 60px);
}
.dp_bg .section-id {
  display: block;
  line-height: 1;
  font-weight: 700;
  letter-spacing: 0.5px;
  margin-bottom: 35px;
}
.dp_bg .section-id {
  font-size: 0.85rem;
  text-transform: uppercase;
}
.dp_bg .font--jakarta .section-id {
  font-family: Plus Jakarta Sans, sans-serif;
}
#features-12.pt-100.shape--bg:after {
  position: absolute;
  content: "";
  z-index: -1;
}
#features-12.pt-100.shape--bg:after {
  width: 48%;
  height: 72%;
  top: 21%;
  left: 52%;
}
.fbox-ico {
  margin-bottom: 20px;
}
.fbox-12-wrapper {
  padding-left: 45px;
}
.fbox-12 {
  padding: 32px 35px;
}
.fbox-12 h5 {
  margin-top: 20px;
  margin-bottom: 10px;
}
.fbox-12 p {
  margin-bottom: 0;
}
.txt-block h2 {
  margin-bottom: 26px;
}
.txt-block .btn {
  margin-top: 25px;
}
.hover--theme:hover {
  color: #fff !important;
  border-color: #e73971 !important;
  background-color: #e73971 !important;
}
.color--theme,
.color--theme span {
  color: #f74780 !important;
}
.shape-ico.color--theme path {
  fill: #feecf2;
}
@media (min-width: 1200px) {
  .btn {
    padding: 0.8rem 1.7rem;
  }
}
@media (min-width: 992px) and (max-width: 1199.95px) {
  .pt-100 {
    padding-top: 80px;
  }
  .s-19 {
    font-size: 1.125rem;
  }
  .s-46 {
    font-size: 2.625rem;
  }
  .btn.btn-sm {
    font-size: 0.975rem;
    padding: 0.65rem 1.4rem;
  }
  .ico-50 .shape-ico svg {
    width: 110px;
    height: 110px;
    top: -30px;
    left: calc(50% - 55px);
  }
  .section-id {
    font-size: 0.85rem;
    margin-bottom: 30px;
  }
  #features-12.pt-100.shape--bg:after {
    width: 50%;
    height: 76%;
    top: 18%;
    left: 50%;
  }
  .fbox-12-wrapper {
    padding-left: 30px;
  }
  .fbox-12 {
    padding: 30px;
  }
  .fbox-ico {
    margin-bottom: 18px;
  }
  .fbox-txt h5 {
    margin-bottom: 12px;
  }
  .txt-block h2 {
    margin-bottom: 20px;
  }
  .txt-block .btn {
    margin-top: 22px;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .pt-100 {
    padding-top: 70px;
  }
  .s-19 {
    font-size: 1.3rem;
  }
  .s-46 {
    font-size: 2.35294rem;
  }
  .btn.btn-sm {
    font-size: 0.975rem;
    padding: 0.65rem 1.35rem;
  }
  .ico-50 .shape-ico svg {
    width: 95px;
    height: 95px;
    top: -25px;
    left: calc(50% - 50px);
  }
  .section-id {
    margin-bottom: 25px;
  }
  #features-12.pt-100.shape--bg:after {
    width: 52%;
    height: 75%;
    top: 19%;
    left: 48%;
  }
  .fbox-12-wrapper {
    padding-left: 0;
  }
  .fbox-12 {
    padding: 22px 18px;
  }
  .fbox-12.mb-30 {
    margin-bottom: 20px;
  }
  .fbox-ico {
    margin-bottom: 18px;
  }
  .fbox-txt h5 {
    margin-bottom: 12px;
  }
  .fbox-12 h5 {
    font-size: 1.3rem;
    margin-bottom: 10px;
  }
  .txt-block h2 {
    margin-bottom: 15px;
  }
  ul.simple-list li:not(:last-child) p {
    margin-bottom: 8px;
  }
  .txt-block .btn {
    margin-top: 15px;
  }
}
@media (max-width: 767px) {
  .block-shadow {
    box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.08);
  }
  .pt-100 {
    padding-top: 70px;
  }
  h2,
  h5 {
    line-height: 1.35;
  }
  .section-id {
    margin-bottom: 24px;
  }
  #features-12.pt-100.shape--bg:after {
    width: 100%;
    height: 66%;
    top: 30.25%;
    left: 0;
  }
  .fbox-12-wrapper {
    text-align: center;
    padding-left: 0;
  }
  #fb-12-1,
  #fb-12-2,
  #fb-12-3 {
    margin-bottom: 40px;
  }
  #fb-12-1 {
    margin-top: 75px;
  }
  #features-12 .txt-block {
    margin-bottom: 40px;
  }
  .fbox-ico {
    margin-bottom: 15px;
  }
  .txt-block h2 {
    margin-bottom: 15px;
  }
  .txt-block .btn {
    margin-top: 10px;
  }
}
@media (min-width: 480px) and (max-width: 767.98px) {
  .font--jakarta h2 {
    line-height: 1.35;
  }
  .font--jakarta h5 {
    line-height: 1.4;
  }
  .s-19 {
    font-size: 1.4375rem;
  }
  .s-46 {
    font-size: 2.25rem;
  }
  p {
    font-size: 1.0625rem;
  }
  .btn {
    font-size: 1.125rem;
  }
  .btn.btn-sm {
    font-size: 1rem;
    padding: 0.7rem 1.5rem;
  }
  .ico-50 .shape-ico svg {
    width: 125px;
    height: 125px;
    top: -35px;
    left: calc(50% - 65px);
  }
  .section-id {
    margin-bottom: 25px;
  }
  .fbox-12 {
    padding: 40px 50px;
  }
  .fbox-ico {
    margin-bottom: 18px;
  }
  .fbox-txt h5 {
    margin-bottom: 15px;
  }
  .txt-block h2 {
    margin-bottom: 20px;
  }
  .txt-block .btn {
    margin-top: 18px;
  }
}
@media (min-width: 414px) and (max-width: 479.98px) {
  .division {
    padding-left: 10px;
    padding-right: 10px;
  }
  .font--jakarta h2 {
    line-height: 1.35;
  }
  .font--jakarta h5 {
    line-height: 1.4;
  }
  .s-19 {
    font-size: 1.3125rem;
  }
  .s-46 {
    font-size: 1.8125rem;
  }
  p {
    font-size: 1.0625rem;
  }
  .btn {
    font-size: 1.125rem;
  }
  .btn.btn-sm {
    font-size: 1rem;
    padding: 0.7rem 1.5rem;
  }
  .ico-50 .shape-ico svg {
    width: 120px;
    height: 120px;
    top: -35px;
    left: calc(50% - 60px);
  }
  #features-12.pt-100.shape--bg:after {
    height: 64%;
    top: 32.35%;
  }
  .fbox-12 {
    padding: 35px 30px;
  }
  #fb-12-1 {
    margin-top: 65px;
  }
  .fbox-ico {
    margin-bottom: 20px;
  }
  .fbox-txt h5 {
    margin-bottom: 15px;
  }
  .txt-block h2 {
    margin-bottom: 15px;
  }
  .txt-block p {
    margin-bottom: 0.875rem;
  }
  .txt-block .btn {
    margin-top: 18px;
  }
}
@media (min-width: 390px) and (max-width: 413.98px) {
  .division {
    padding-left: 3px;
    padding-right: 3px;
  }
  .font--jakarta h2 {
    line-height: 1.35;
  }
  .font--jakarta h5 {
    line-height: 1.4;
  }
  .s-19 {
    font-size: 1.3125rem;
  }
  .s-46 {
    font-size: 1.8125rem;
  }
  p {
    font-size: 1.0625rem;
  }
  .btn {
    font-size: 1.0625rem;
  }
  .btn.btn-sm {
    font-size: 1rem;
    padding: 0.7rem 1.5rem;
  }
  .ico-50 .shape-ico svg {
    width: 120px;
    height: 120px;
    top: -35px;
    left: calc(50% - 60px);
  }
  #features-12.pt-100.shape--bg:after {
    height: 64%;
    top: 32.6%;
  }
  .fbox-12 {
    padding: 32px 25px;
  }
  #fb-12-1 {
    margin-top: 60px;
  }
  .fbox-ico {
    margin-bottom: 20px;
  }
  .fbox-txt h5 {
    margin-bottom: 15px;
  }
  .txt-block h2 {
    margin-bottom: 14px;
  }
  .txt-block p {
    margin-bottom: 0.875rem;
  }
  .txt-block .btn {
    margin-top: 18px;
  }
}
@media (min-width: 321px) and (max-width: 389.98px) {
  .division {
    padding-left: 3px;
    padding-right: 3px;
  }
  .font--jakarta h2 {
    line-height: 1.35;
  }
  .font--jakarta h5 {
    line-height: 1.4;
  }
  .s-19 {
    font-size: 1.217948rem;
  }
  .s-46 {
    font-size: 1.730769rem;
  }
  .btn,
  .btn.btn-sm {
    font-size: 1rem;
  }
  .btn.btn-sm {
    padding: 0.7rem 1.5rem;
  }
  .ico-50 .shape-ico svg {
    width: 120px;
    height: 120px;
    top: -35px;
    left: calc(50% - 60px);
  }
  .section-id {
    margin-bottom: 25px;
  }
  #features-12.pt-100.shape--bg:after {
    height: 65%;
    top: 32%;
  }
  .fbox-12 {
    padding: 32px 25px;
  }
  #fb-12-1 {
    margin-top: 50px;
  }
  #features-12 .txt-block {
    margin-bottom: 35px;
  }
  .fbox-ico {
    margin-bottom: 20px;
  }
  .fbox-txt h5 {
    margin-bottom: 15px;
  }
  .txt-block h2 {
    margin-bottom: 14px;
  }
  .txt-block p {
    margin-bottom: 0.897435rem;
  }
  .txt-block .btn {
    margin-top: 18px;
  }
}
@media (max-width: 320.98px) {
  .pt-100 {
    padding-top: 60px;
  }
  .font--jakarta h2 {
    line-height: 1.35;
  }
  .font--jakarta h5 {
    line-height: 1.4;
  }
  .s-19 {
    font-size: 1.285714rem;
  }
  .s-46 {
    font-size: 1.714285rem;
  }
  .btn,
  .btn.btn-sm {
    font-size: 1rem;
  }
  .btn.btn-sm {
    padding: 0.7rem 1.5rem;
  }
  .ico-50 .shape-ico svg {
    width: 100px;
    height: 100px;
  }
  .ico-50 .shape-ico svg {
    top: -30px;
    left: calc(50% - 55px);
  }
  #features-12.pt-100.shape--bg:after {
    height: 65%;
    top: 32%;
  }
  .fbox-12 {
    padding: 30px 25px;
  }
  #fb-12-1,
  #fb-12-2,
  #fb-12-3 {
    margin-bottom: 30px;
  }
  #fb-12-1 {
    margin-top: 50px;
  }
  #features-12 .txt-block {
    margin-bottom: 30px;
  }
  .fbox-ico {
    margin-bottom: 18px;
  }
  .fbox-txt h5 {
    margin-bottom: 12px;
  }
  .txt-block h2 {
    margin-bottom: 14px;
  }
  .txt-block p {
    margin-bottom: 0.85714rem;
  }
  .txt-block .btn {
    margin-top: 16px;
  }
}
/*! CSS Used keyframes */
@keyframes fadeInLeft {
  0% {
    opacity: 0;
    transform: translate3d(-20%, 0, 0);
  }
  to {
    opacity: 1;
    transform: none;
  }
}
@keyframes fadeInRight {
  0% {
    opacity: 0;
    transform: translate3d(20%, 0, 0);
  }
  to {
    opacity: 1;
    transform: none;
  }
}
/*! CSS Used fontfaces */
@font-face {
  font-family: "Plus Jakarta Sans";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/plusjakartasans/v8/LDIoaomQNQcsA88c7O9yZ4KMCoOg4Ko70yyygA.woff2)
    format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}
@font-face {
  font-family: "Plus Jakarta Sans";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/plusjakartasans/v8/LDIoaomQNQcsA88c7O9yZ4KMCoOg4Ko50yyygA.woff2)
    format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329,
    U+1EA0-1EF9, U+20AB;
}
@font-face {
  font-family: "Plus Jakarta Sans";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/plusjakartasans/v8/LDIoaomQNQcsA88c7O9yZ4KMCoOg4Ko40yyygA.woff2)
    format("woff2");
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Plus Jakarta Sans";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/plusjakartasans/v8/LDIoaomQNQcsA88c7O9yZ4KMCoOg4Ko20yw.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191,
    U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Plus Jakarta Sans";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/plusjakartasans/v8/LDIoaomQNQcsA88c7O9yZ4KMCoOg4Ko70yyygA.woff2)
    format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}
@font-face {
  font-family: "Plus Jakarta Sans";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/plusjakartasans/v8/LDIoaomQNQcsA88c7O9yZ4KMCoOg4Ko50yyygA.woff2)
    format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329,
    U+1EA0-1EF9, U+20AB;
}
@font-face {
  font-family: "Plus Jakarta Sans";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/plusjakartasans/v8/LDIoaomQNQcsA88c7O9yZ4KMCoOg4Ko40yyygA.woff2)
    format("woff2");
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Plus Jakarta Sans";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/plusjakartasans/v8/LDIoaomQNQcsA88c7O9yZ4KMCoOg4Ko20yw.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191,
    U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Plus Jakarta Sans";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/plusjakartasans/v8/LDIoaomQNQcsA88c7O9yZ4KMCoOg4Ko70yyygA.woff2)
    format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}
@font-face {
  font-family: "Plus Jakarta Sans";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/plusjakartasans/v8/LDIoaomQNQcsA88c7O9yZ4KMCoOg4Ko50yyygA.woff2)
    format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329,
    U+1EA0-1EF9, U+20AB;
}
@font-face {
  font-family: "Plus Jakarta Sans";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/plusjakartasans/v8/LDIoaomQNQcsA88c7O9yZ4KMCoOg4Ko40yyygA.woff2)
    format("woff2");
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Plus Jakarta Sans";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/plusjakartasans/v8/LDIoaomQNQcsA88c7O9yZ4KMCoOg4Ko20yw.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191,
    U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Plus Jakarta Sans";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/plusjakartasans/v8/LDIoaomQNQcsA88c7O9yZ4KMCoOg4Ko70yyygA.woff2)
    format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}
@font-face {
  font-family: "Plus Jakarta Sans";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/plusjakartasans/v8/LDIoaomQNQcsA88c7O9yZ4KMCoOg4Ko50yyygA.woff2)
    format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329,
    U+1EA0-1EF9, U+20AB;
}
@font-face {
  font-family: "Plus Jakarta Sans";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/plusjakartasans/v8/LDIoaomQNQcsA88c7O9yZ4KMCoOg4Ko40yyygA.woff2)
    format("woff2");
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Plus Jakarta Sans";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/plusjakartasans/v8/LDIoaomQNQcsA88c7O9yZ4KMCoOg4Ko20yw.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191,
    U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: flaticon;
  src: url(https://martex.vercel.app/_next/static/media/flaticon.8d531de8.ttf)
      format("truetype"),
    url(https://martex.vercel.app/_next/static/media/flaticon.79f5ea8a.woff)
      format("woff"),
    url(https://martex.vercel.app/_next/static/media/flaticon.cd511ef5.woff2)
      format("woff2"),
    url(https://martex.vercel.app/_next/static/media/flaticon.4f310b80.eot#iefix)
      format("embedded-opentype"),
    url(https://martex.vercel.app/_next/static/media/flaticon.7d52eda6.svg#flaticon)
      format("svg");
}

/* ------------------------------------------------------------------------------------------------------------ */

/* ------------------------------------------------------------------------------------------------------------ */

/*! CSS Used from: https://martex.vercel.app/_next/static/css/3f475461ef079320.css */
.dd_pt h2,
.dd_pt h6 {
  font-family: Plus Jakarta Sans, sans-serif;
  line-height: 1.35;
  font-weight: 700;
}
.dd_pt .fbox-txt p {
  font-size: 1.2rem;
  padding: 0 4%;
  margin-bottom: 0;
}
@media (min-width: 992px) and (max-width: 1199.98px) {
    .dd_pt  .fbox-txt p {
    font-size: 1.125rem;
    padding: 0 4%;
    margin-bottom: 0;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
    .dd_pt  .fb-1,
    .dd_pt  .fb-2 {
    margin-bottom: 40px;
  }
  .dd_pt  .fbox-txt p {
    font-size: 1.1875rem;
    padding: 0 13%;
    margin-bottom: 0;
  }
}
@media (min-width: 480px) and (max-width: 767.98px) {
    .dd_pt .fb-1,
    .dd_pt .fb-2 {
    margin-bottom: 40px;
  }
  .dd_pt  .fbox-txt p {
    font-size: 1.0625rem;
    padding: 0 10%;
    margin-bottom: 0;
  }
}
@media (min-width: 414px) and (max-width: 479.98px) {
    .dd_pt .fb-1,
    .dd_pt .fb-2 {
    margin-bottom: 40px;
  }
  .dd_pt  .fbox-txt p {
    font-size: 1.0625rem;
    padding: 0;
    margin-bottom: 0;
  }
}
@media (min-width: 390px) and (max-width: 413.98px) {
    .dd_pt  .fb-1,
    .dd_pt  .fb-2 {
    margin-bottom: 40px;
  }
  .dd_pt .fbox-txt p {
    font-size: 1.0625rem;
    padding: 0;
    margin-bottom: 0;
  }
}
@media (min-width: 321px) and (max-width: 389.98px) {
    .dd_pt .fb-1,
    .dd_pt .fb-2 {
    margin-bottom: 40px;
  }
  .dd_pt .fbox-txt p {
    font-size: 1rem;
    padding: 0;
    margin-bottom: 0;
  }
}
@media (max-width: 320.95px) {
    .dd_pt .fb-1,
    .dd_pt .fb-2 {
    margin-bottom: 40px;
  }
  .dd_pt .fbox-txt p {
    font-size: 0.9rem;
    padding: 0 3%;
    margin-bottom: 0;
  }
}
*,
:after,
:before {
  box-sizing: border-box;
}
.dd_pt h2,
.dd_pt h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
  color: var(--bs-heading-color);
}
.dd_pt h2 {
  font-size: calc(1.325rem + 0.9vw);
}
@media (min-width: 1200px) {
    .dd_pt  h2 {
    font-size: 2rem;
  }
}
.dd_pt h6 {
  font-size: 1rem;
}
.dd_pt p {
  margin-top: 0;
  margin-bottom: 1rem;
}
.dd_pt svg {
  vertical-align: middle;
}
.dd_pt .container {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 576px) {
    .dd_pt .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
    .dd_pt .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
    .dd_pt .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
    .dd_pt .container {
    max-width: 1140px;
  }
}
@media (min-width: 1400px) {
    .dd_pt .container {
    max-width: 1320px;
  }
}
.dd_pt .row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
}
.dd_pt .row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}
.dd_pt .col {
  flex: 1 0;
}
.dd_pt .row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}
@media (min-width: 768px) {
    .dd_pt .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .dd_pt .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
}
@media (min-width: 992px) {
    .dd_pt  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }
}
.dd_pt .justify-content-center {
  justify-content: center !important;
}
.dd_pt span[class^="flaticon-"]:before {
  font-family: flaticon !important;
  font-style: normal;
  font-weight: 400 !important;
  font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  font-size: 20px;
  line-height: 1 !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.dd_pt .flaticon-idea:before {
  content: "\f158";
}
.dd_pt .flaticon-trophy:before {
  content: "\f15d";
}
.dd_pt .flaticon-graphics:before {
  content: "\f160";
}
.dd_pt .flaticon-graphic:before {
  content: "\f173";
}
.dd_pt .flaticon-search-engine-1:before {
  content: "\f194";
}
.dd_pt .flaticon-wireframe:before {
  content: "\f1c2";
}
.dd_pt .fadeInUp {
  animation-name: fadeInUp;
}
.dd_pt .container,
section {
  position: relative !important;
  z-index: 3;
}
.pt-100 {
  padding-top: 100px;
}
.mb-70 {
  margin-bottom: 70px;
}
h2,
h6 {
  color: #353f4f;
  font-weight: 600;
  letter-spacing: 0;
  margin-bottom: 0;
}
.font--jakarta h2,
.font--jakarta h6 {
  font-family: Plus Jakarta Sans, sans-serif;
  line-height: 1.35;
}
.font--jakarta h2 {
  line-height: 1.25;
}
p {
  font-size: 1rem;
}
.s-21 {
  font-size: 1.3125rem;
}
.s-22 {
  font-size: 1.375rem;
}
.s-50 {
  font-size: 3.125rem;
}
.w-700 {
  font-weight: 700;
}
.ico-50 [class*="flaticon-"]:after,
.ico-50 [class*="flaticon-"]:before {
  font-size: 3.125rem;
}
.shape-ico {
  position: relative;
  display: inline-block;
  margin: 0 auto;
}
.shape-ico span {
  position: relative;
  z-index: 2;
}
.shape-ico svg {
  position: absolute;
  z-index: 1;
}
.ico-50 .shape-ico svg {
  width: 130px;
  height: 130px;
  top: -35px;
  left: calc(50% - 60px);
}
.fbox-11 .ico-50 .shape-ico svg {
  top: -40px;
  left: calc(50% - 70px);
}
.section-title {
  text-align: center;
}
.section-title h2 {
  margin-bottom: 0;
}
.section-title h2.w-700 {
  letter-spacing: -0.5px;
}
.section-title p.s-21 {
  margin-top: 18px;
  margin-bottom: 0;
}
.color--grey {
  color: #757575;
}
.rows-3 .fb-1,
.rows-3 .fb-2,
.rows-3 .fb-3,
.rows-3 .fb-4 {
  margin-bottom: 40px;
}
.fbox-ico {
  margin-bottom: 20px;
}
.fbox-11 {
  display: flex;
  position: relative;
  flex-flow: row wrap;
  align-items: stretch !important;
  justify-content: flex-start;
}
.fbox-ico-wrap {
  position: relative;
  margin-right: 25px;
}
.fbox-11 .fbox-ico {
  margin-top: 4px;
}
.fbox-11 .fbox-txt {
  overflow: hidden;
  flex: 1 1;
  max-width: 100%;
}
.fbox-wrapper .row-cols-md-2 h6 {
  margin-bottom: 10px;
}
.fbox-wrapper p {
  margin-bottom: 0;
}
.color--theme,
.color--theme span {
  color: #f74780 !important;
}
.shape-ico.color--theme path {
  fill: #feecf2;
}
@media (min-width: 992px) and (max-width: 1199.95px) {
  .pt-100 {
    padding-top: 80px;
  }
  .mb-70 {
    margin-bottom: 60px;
  }
  .s-21,
  .s-22 {
    font-size: 1.25rem;
  }
  .s-50 {
    font-size: 2.875rem;
  }
  .ico-50 .shape-ico svg {
    width: 110px;
    height: 110px;
    top: -30px;
    left: calc(50% - 55px);
  }
  .fbox-11 .ico-50 .shape-ico svg {
    top: -35px;
    left: calc(50% - 60px);
  }
  .section-title p.s-21 {
    margin-top: 15px;
  }
  .section-title p.s-21 {
    font-size: 1.18755rem;
  }
  .fbox-ico {
    margin-bottom: 18px;
  }
  .row-cols-md-2 .fbox-ico {
    margin-bottom: 15px;
  }
  .fbox-wrapper .row-cols-md-2 h6,
  .features-section h6.s-22 {
    margin-bottom: 12px;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .pt-100 {
    padding-top: 70px;
  }
  .mb-70 {
    margin-bottom: 50px;
  }
  .s-21,
  .s-22 {
    font-size: 1.397058rem;
  }
  .s-50 {
    font-size: 2.64705rem;
  }
  .ico-50 .shape-ico svg {
    width: 95px;
    height: 95px;
    top: -25px;
    left: calc(50% - 50px);
  }
  .fbox-11 .ico-50 .shape-ico svg {
    top: -30px;
    left: calc(50% - 52px);
  }
  .section-title.mb-70 {
    margin-bottom: 40px;
  }
  .section-title p.s-21 {
    font-size: 1.32352rem;
    margin-top: 12px;
  }
  .row-cols-md-2 .fb-1,
  .row-cols-md-2 .fb-2 {
    margin-bottom: 35px;
  }
  .fbox-ico {
    margin-bottom: 18px;
  }
  .fbox-wrapper .row-cols-md-2 .ico-50 [class*="flaticon-"]:after,
  .fbox-wrapper .row-cols-md-2 .ico-50 [class*="flaticon-"]:before {
    font-size: 3.75rem;
  }
  .fbox-ico-wrap {
    margin-right: 18px;
  }
  .fbox-wrapper .row-cols-md-2 h6,
  .features-section h6.s-22 {
    margin-bottom: 12px;
  }
}
@media (max-width: 767px) {
  .pt-100 {
    padding-top: 70px;
  }
  .mb-70 {
    margin-bottom: 50px;
  }
  h2,
  h6 {
    line-height: 1.35;
  }
  .section-title.mb-70 {
    margin-bottom: 50px;
  }
  .fb-1,
  .fb-2,
  .row-cols-md-2 .fb-3,
  .rows-3 .fbox-11.fb-5 {
    margin-bottom: 40px;
  }
  .fbox-ico {
    margin-bottom: 15px;
  }
  .fbox-11 {
    display: block;
    text-align: center;
  }
  .fbox-11 .fbox-ico {
    margin-top: 0;
  }
  .fbox-ico-wrap {
    margin-right: 0;
  }
}
@media (min-width: 480px) and (max-width: 767.98px) {
  .font--jakarta h2 {
    line-height: 1.35;
  }
  .font--jakarta h6 {
    line-height: 1.4;
  }
  .s-21,
  .s-22 {
    font-size: 1.4375rem;
  }
  .s-50 {
    font-size: 2.375rem;
  }
  p {
    font-size: 1.0625rem;
  }
  .ico-50 .shape-ico svg {
    width: 125px;
    height: 125px;
    top: -35px;
    left: calc(50% - 65px);
  }
  .section-title p.s-21 {
    font-size: 1.21875rem;
    margin-top: 12px;
  }
  .fbox-ico {
    margin-bottom: 18px;
  }
  .fbox-wrapper .ico-50 [class*="flaticon-"]:after,
  .fbox-wrapper .ico-50 [class*="flaticon-"]:before {
    font-size: 4rem;
  }
  .features-section h6.s-22 {
    font-size: 1.625rem;
  }
  .features-section h6 {
    margin-bottom: 15px;
  }
  .rows-3 .fbox-11 p {
    padding: 0 2%;
  }
}
@media (min-width: 414px) and (max-width: 479.98px) {
  .division {
    padding-left: 10px;
    padding-right: 10px;
  }
  .font--jakarta h2 {
    line-height: 1.35;
  }
  .font--jakarta h6 {
    line-height: 1.4;
  }
  .s-21,
  .s-22 {
    font-size: 1.3125rem;
  }
  .s-50 {
    font-size: 2.0625rem;
  }
  p {
    font-size: 1.0625rem;
  }
  .ico-50 .shape-ico svg {
    width: 120px;
    height: 120px;
    top: -35px;
    left: calc(50% - 60px);
  }
  .fbox-11 .ico-50 .shape-ico svg {
    top: -35px;
    left: calc(50% - 65px);
  }
  .section-title.mb-70 {
    margin-bottom: 45px;
  }
  .section-title h2 {
    padding: 0 1%;
  }
  .section-title p.s-21 {
    font-size: 1.1875rem;
    padding: 0 3%;
    margin-top: 12px;
  }
  .fbox-ico {
    margin-bottom: 20px;
  }
  .fbox-wrapper .ico-50 [class*="flaticon-"]:after,
  .fbox-wrapper .ico-50 [class*="flaticon-"]:before {
    font-size: 3.65rem;
  }
  .features-section h6.s-22 {
    font-size: 1.4375rem;
  }
  .fbox-wrapper .row-cols-md-2 h6,
  .features-section h6 {
    margin-bottom: 15px;
  }
  .rows-3 .fbox-11 p {
    padding: 0 2%;
  }
}
@media (min-width: 390px) and (max-width: 413.98px) {
  .division {
    padding-left: 3px;
    padding-right: 3px;
  }
  .font--jakarta h2 {
    line-height: 1.35;
  }
  .font--jakarta h6 {
    line-height: 1.4;
  }
  .s-21,
  .s-22 {
    font-size: 1.3125rem;
  }
  .s-50 {
    font-size: 2rem;
  }
  p {
    font-size: 1.0625rem;
  }
  .ico-50 .shape-ico svg {
    width: 120px;
    height: 120px;
    top: -35px;
    left: calc(50% - 60px);
  }
  .fbox-11 .ico-50 .shape-ico svg {
    top: -35px;
    left: calc(50% - 65px);
  }
  .section-title.mb-70 {
    margin-bottom: 40px;
  }
  .section-title h2 {
    padding: 0 1%;
  }
  .section-title p.s-21 {
    font-size: 1.1875rem;
    padding: 0 2%;
    margin-top: 12px;
  }
  .fbox-ico {
    margin-bottom: 20px;
  }
  .fbox-wrapper .ico-50 [class*="flaticon-"]:after,
  .fbox-wrapper .ico-50 [class*="flaticon-"]:before {
    font-size: 3.65rem;
  }
  .features-section h6.s-22 {
    font-size: 1.4375rem;
  }
  .fbox-wrapper .row-cols-md-2 h6,
  .features-section h6 {
    margin-bottom: 15px;
  }
  .rows-3 .fbox-11 p {
    padding: 0 2%;
  }
}
@media (min-width: 321px) and (max-width: 389.98px) {
  .division {
    padding-left: 3px;
    padding-right: 3px;
  }
  .font--jakarta h2 {
    line-height: 1.35;
  }
  .font--jakarta h6 {
    line-height: 1.4;
  }
  .s-21,
  .s-22 {
    font-size: 1.217948rem;
  }
  .s-50 {
    font-size: 1.923076rem;
  }
  .ico-50 .shape-ico svg {
    width: 120px;
    height: 120px;
    top: -35px;
    left: calc(50% - 60px);
  }
  .fbox-11 .ico-50 .shape-ico svg {
    top: -35px;
    left: calc(50% - 65px);
  }
  .section-title.mb-70 {
    margin-bottom: 40px;
  }
  .section-title p.s-21 {
    font-size: 1.15384rem;
    padding: 0 2%;
    margin-top: 12px;
  }
  .fbox-ico {
    margin-bottom: 20px;
  }
  .fbox-wrapper .ico-50 [class*="flaticon-"]:after,
  .fbox-wrapper .ico-50 [class*="flaticon-"]:before {
    font-size: 3.6rem;
  }
  .features-section h6.s-22 {
    font-size: 1.34615rem;
  }
  .fbox-wrapper .row-cols-md-2 h6,
  .features-section h6 {
    margin-bottom: 15px;
  }
  .rows-3 .fbox-11 p {
    padding: 0 2%;
  }
}
@media (max-width: 320.98px) {
  .pt-100 {
    padding-top: 60px;
  }
  .font--jakarta h2 {
    line-height: 1.35;
  }
  .font--jakarta h6 {
    line-height: 1.4;
  }
  .s-21,
  .s-22 {
    font-size: 1.285714rem;
  }
  .s-50 {
    font-size: 1.92857rem;
  }
  .ico-50 .shape-ico svg {
    width: 100px;
    height: 100px;
  }
  .fbox-11 .ico-50 .shape-ico svg,
  .ico-50 .shape-ico svg {
    top: -30px;
    left: calc(50% - 55px);
  }
  .section-title.mb-70 {
    margin-bottom: 35px;
  }
  .section-title h2 {
    padding: 0 1%;
  }
  .section-title p.s-21 {
    font-size: 1.285714rem;
    margin-top: 10px;
  }
  .fb-1,
  .fb-2,
  .row-cols-md-2 .fb-3 {
    margin-bottom: 30px;
  }
  .fbox-ico {
    margin-bottom: 18px;
  }
  .fbox-wrapper .ico-50 [class*="flaticon-"]:after,
  .fbox-wrapper .ico-50 [class*="flaticon-"]:before {
    font-size: 3.6rem;
  }
  .features-section h6.s-22 {
    font-size: 1.35714rem;
  }
  .fbox-wrapper .row-cols-md-2 h6,
  .features-section h6 {
    margin-bottom: 12px;
  }
  .rows-3 .fbox-11 p {
    padding: 0 4%;
  }
}
/*! CSS Used keyframes */
@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translate3d(0, 30%, 0);
  }
  to {
    opacity: 1;
    transform: none;
  }
}
/*! CSS Used fontfaces */
@font-face {
  font-family: "Plus Jakarta Sans";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/plusjakartasans/v8/LDIoaomQNQcsA88c7O9yZ4KMCoOg4Ko70yyygA.woff2)
    format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}
@font-face {
  font-family: "Plus Jakarta Sans";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/plusjakartasans/v8/LDIoaomQNQcsA88c7O9yZ4KMCoOg4Ko50yyygA.woff2)
    format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329,
    U+1EA0-1EF9, U+20AB;
}
@font-face {
  font-family: "Plus Jakarta Sans";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/plusjakartasans/v8/LDIoaomQNQcsA88c7O9yZ4KMCoOg4Ko40yyygA.woff2)
    format("woff2");
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Plus Jakarta Sans";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/plusjakartasans/v8/LDIoaomQNQcsA88c7O9yZ4KMCoOg4Ko20yw.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191,
    U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Plus Jakarta Sans";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/plusjakartasans/v8/LDIoaomQNQcsA88c7O9yZ4KMCoOg4Ko70yyygA.woff2)
    format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}
@font-face {
  font-family: "Plus Jakarta Sans";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/plusjakartasans/v8/LDIoaomQNQcsA88c7O9yZ4KMCoOg4Ko50yyygA.woff2)
    format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329,
    U+1EA0-1EF9, U+20AB;
}
@font-face {
  font-family: "Plus Jakarta Sans";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/plusjakartasans/v8/LDIoaomQNQcsA88c7O9yZ4KMCoOg4Ko40yyygA.woff2)
    format("woff2");
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Plus Jakarta Sans";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/plusjakartasans/v8/LDIoaomQNQcsA88c7O9yZ4KMCoOg4Ko20yw.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191,
    U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Plus Jakarta Sans";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/plusjakartasans/v8/LDIoaomQNQcsA88c7O9yZ4KMCoOg4Ko70yyygA.woff2)
    format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}
@font-face {
  font-family: "Plus Jakarta Sans";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/plusjakartasans/v8/LDIoaomQNQcsA88c7O9yZ4KMCoOg4Ko50yyygA.woff2)
    format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329,
    U+1EA0-1EF9, U+20AB;
}
@font-face {
  font-family: "Plus Jakarta Sans";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/plusjakartasans/v8/LDIoaomQNQcsA88c7O9yZ4KMCoOg4Ko40yyygA.woff2)
    format("woff2");
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Plus Jakarta Sans";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/plusjakartasans/v8/LDIoaomQNQcsA88c7O9yZ4KMCoOg4Ko20yw.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191,
    U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Plus Jakarta Sans";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/plusjakartasans/v8/LDIoaomQNQcsA88c7O9yZ4KMCoOg4Ko70yyygA.woff2)
    format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}
@font-face {
  font-family: "Plus Jakarta Sans";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/plusjakartasans/v8/LDIoaomQNQcsA88c7O9yZ4KMCoOg4Ko50yyygA.woff2)
    format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329,
    U+1EA0-1EF9, U+20AB;
}
@font-face {
  font-family: "Plus Jakarta Sans";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/plusjakartasans/v8/LDIoaomQNQcsA88c7O9yZ4KMCoOg4Ko40yyygA.woff2)
    format("woff2");
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Plus Jakarta Sans";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/plusjakartasans/v8/LDIoaomQNQcsA88c7O9yZ4KMCoOg4Ko20yw.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191,
    U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: flaticon;
  src: url(https://martex.vercel.app/_next/static/media/flaticon.8d531de8.ttf)
      format("truetype"),
    url(https://martex.vercel.app/_next/static/media/flaticon.79f5ea8a.woff)
      format("woff"),
    url(https://martex.vercel.app/_next/static/media/flaticon.cd511ef5.woff2)
      format("woff2"),
    url(https://martex.vercel.app/_next/static/media/flaticon.4f310b80.eot#iefix)
      format("embedded-opentype"),
    url(https://martex.vercel.app/_next/static/media/flaticon.7d52eda6.svg#flaticon)
      format("svg");
}
