/* .slider {
  position: relative;
  height: 550px;
  overflow: hidden;
  margin: 40px;
  border-radius: 10px;
}
.slide-container {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.slide {
  flex: 1;
  height: 100%;
}
.slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
@media (max-width: 768px) {
  .slide-container {
    width: 100%;
    flex-direction: column;
  }
  .slider {
    height: 300px;
  }
  .slide img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
} */

.cd-words-wrapper b {
  display: inline-block;
  opacity: 0;
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  transform: translateX(100%);
  transition: opacity 0.3s, transform 0.3s;
}

.cd-words-wrapper b.is-visible {
  opacity: 1;
  transform: translateX(0);
}

.cd-words-wrapper b.is-hidden {
  opacity: 0;
  transform: translateX(-100%);
}

/*! CSS Used from: https://html.hixstudio.net/seomy-prv/seomy/assets/css/bootstrap.css */
*,
::after,
::before {
  box-sizing: border-box;
}
h4 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}
h4 {
  font-size: calc(1.275rem + 0.3vw);
}
@media (min-width: 1200px) {
  h4 {
    font-size: 1.5rem;
  }
}
p {
  margin-top: 0;
  margin-bottom: 1rem;
}
b {
  font-weight: bolder;
}
a {
  color: #0d6efd;
  text-decoration: underline;
}
a:hover {
  color: #0a58ca;
}
img,
svg {
  vertical-align: middle;
}
.container {
  width: 100%;
  padding-right: var(--bs-gutter-x, 15px);
  padding-left: var(--bs-gutter-x, 15px);
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}
@media (min-width: 1400px) {
  .container {
    max-width: 1200px;
  }
}
.row {
  --bs-gutter-x: 30px;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(var(--bs-gutter-y) * -1);
  margin-right: calc(var(--bs-gutter-x) * -0.5);
  margin-left: calc(var(--bs-gutter-x) * -0.5);
}
.row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}
@media (min-width: 992px) {
  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
}
@media (min-width: 1200px) {
  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
}
@media (min-width: 1400px) {
  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
}
.d-flex {
  display: flex !important;
}
.d-none {
  display: none !important;
}
.align-items-center {
  align-items: center !important;
}
@media (min-width: 992px) {
  .d-lg-block {
    display: block !important;
  }
}
/*! CSS Used from: https://html.hixstudio.net/seomy-prv/seomy/assets/css/modernizr.css */
.cd-headline {
  font-size: 3rem;
  line-height: 1.2;
}
@media only screen and (min-width: 768px) {
  .cd-headline {
    font-size: 4.4rem;
    font-weight: 300;
  }
}
@media only screen and (min-width: 1170px) {
  .cd-headline {
    font-size: 6rem;
  }
}
.cd-words-wrapper {
  display: inline-block;
  position: relative;
  text-align: left;
}
.cd-words-wrapper b {
  display: inline-block;
  position: absolute;
  white-space: nowrap;
  left: 0;
  top: 0;
}
.cd-words-wrapper b.is-visible {
  position: relative;
}
.no-js .cd-words-wrapper b {
  opacity: 0;
}
.no-js .cd-words-wrapper b.is-visible {
  opacity: 1;
}
.cd-headline.clip span {
  display: inline-block;
  padding: 0 0 20px 0;
}
.cd-headline.clip .cd-words-wrapper {
  overflow: hidden;
  vertical-align: top;
}
.cd-headline.clip .cd-words-wrapper::after {
  content: "";
  position: absolute;
  top: 5px;
  right: 0;
  width: 2px;
  height: 80%;
  background-color: #aebcb9;
}
@media (max-width: 767px) {
  .cd-headline.clip .cd-words-wrapper::after {
    height: 60%;
  }
}
.cd-headline.clip b {
  opacity: 0;
}
.cd-headline.clip b.is-visible {
  opacity: 1;
}
/*! CSS Used from: https://html.hixstudio.net/seomy-prv/seomy/assets/css/spacing.css */
.mb-30 {
  margin-bottom: 30px;
}
.pt-150 {
  padding-top: 150px;
}
/*! CSS Used from: https://html.hixstudio.net/seomy-prv/seomy/assets/css/main.css */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
a {
  text-decoration: none;
}
h4 {
  font-family: var(--tp-ff-heading);
  color: var(--tp-heading-primary);
  margin-top: 0px;
  font-weight: 700;
  line-height: 1.2;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
h4 {
  font-size: 20px;
}
p {
  font-family: var(--tp-ff-p);
  font-size: 16px;
  font-weight: 400;
  color: var(--tp-text-body);
  margin-bottom: 15px;
  line-height: 26px;
}
a,
p,
h4 {
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
a:focus {
  text-decoration: none;
  outline: none;
}
a:focus,
a:hover {
  color: inherit;
  text-decoration: none;
}
a {
  color: inherit;
  outline: none;
  border: none;
  background: transparent;
}
::selection {
  background: var(--tp-theme-primary);
  color: var(--tp-common-white);
  text-shadow: none;
}
*::placeholder {
  color: var(--tp-common-black);
  font-size: var(--tp-fz-body);
  opacity: 1;
}
img {
  max-width: 100%;
  height: auto;
}
.blue-btn {
  font-family: var(--tp-ff-jakarta);
  font-weight: 600;
  font-size: 16px;
  line-height: 1;
  color: var(--tp-common-white);
  background-color: var(--tp-theme-blue);
  display: inline-block;
  padding: 14px 32px;
  border: 2px solid var(--tp-theme-blue);
  border-radius: 30px;
  text-align: center;
}
.blue-btn:hover {
  background-color: var(--tp-common-white);
  color: var(--tp-theme-blue);
}
.banner-4-shape-one {
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
}
@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .banner-4-shape-one img {
    width: 700px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .banner-4-shape-one img {
    width: 450px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-4-shape-one img {
    width: 500px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-4-shape-one img {
    width: 400px;
  }
}
.banner-4-shape-two {
  position: absolute;
  top: 0;
  right: 0;
  z-index: -2;
}
@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .banner-4-shape-two img {
    width: 700px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .banner-4-shape-two img {
    width: 450px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-4-shape-two img {
    width: 500px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-4-shape-two img {
    width: 400px;
  }
}
.banner-4-shape-three {
  position: absolute;
  top: 0;
  right: 0;
  z-index: -2;
}
@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .banner-4-shape-three img {
    width: 700px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .banner-4-shape-three img {
    width: 450px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-4-shape-three img {
    width: 500px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-4-shape-three img {
    width: 400px;
  }
}
.banner-4-shape-six {
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
}
.banner-4-shape-seven {
  position: absolute;
  left: 160px;
  bottom: 35px;
  z-index: -1;
  animation: upslide 4s linear forwards infinite alternate;
}
.banner-4-shape-eight {
  position: absolute;
  left: 660px;
  top: 345px;
  z-index: -1;
  animation: bannerAnimationTwo 6s infinite linear;
}
.banner-4-shape-nine {
  position: absolute;
  left: 750px;
  bottom: 280px;
  z-index: -1;
  animation: bannerAnimationTwo 8s infinite linear;
}
.banner-4-shape-ten {
  position: absolute;
  right: 610px;
  bottom: 90px;
  animation: bannerAnimationTwo 7s infinite linear;
}
.banner-4-content p {
  font-family: var(--tp-ff-jakarta);
  color: var(--tp-grey-8);
  font-size: 20px;
  line-height: 1.5;
  margin-bottom: 38px;
  margin-top: 16px;
}
@media (max-width: 767px) {
  .banner-4-content p {
    font-size: 17px;
  }
}
@media (max-width: 767px) {
  .banner-4-content p br {
    display: none;
  }
}
.banner-4-title {
  color: var(--tp-common-11);
  font-family: var(--tp-ff-jakarta);
  font-weight: 700;
  font-size: 100px;
  line-height: 1;
}
@media only screen and (min-width: 1600px) and (max-width: 1700px) {
  .banner-4-title {
    font-size: 80px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-4-title {
    font-size: 70px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-4-title {
    font-size: 85px;
  }
}
@media (max-width: 767px) {
  .banner-4-title {
    font-size: 40px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .banner-4-title {
    font-size: 48px;
  }
}
.banner-4-title b {
  background: linear-gradient(90deg, #100f0f 0%, #444141 43.75%, #eb0808 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-fill-color: transparent;
  padding: 0 0 20px 0;
}
.banner-4-btn .blue-btn {
  padding: 17px 31px;
}
@media (max-width: 767px) {
  .banner-4-spaces {
    padding-top: 80px;
    padding-bottom: 100px;
  }
}
.contact-4-icon i {
  font-size: 24px;
  margin-right: 14px;
}
.contact-4-text span {
  font-family: var(--tp-ff-jakarta);
  font-size: 14px;
  line-height: 24px;
  color: #55585b;
  display: block;
  line-height: 24px;
}
.contact-4-text a {
  display: inline-block;
  color: var(--tp-common-11);
  font-weight: 600;
  font-size: 20px;
  font-family: var(--tp-ff-jakarta);
}
.contact-4-text a:hover {
  color: var(--tp-theme-blue);
}
/*! CSS Used keyframes */
@keyframes upslide {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-20px);
  }
  60% {
    transform: translateY(-10px);
  }
}
@keyframes bannerAnimationTwo {
  0% {
    transform: translate(0px, 0px);
  }
  20% {
    transform: translate(20px, -5px);
  }
  40% {
    transform: translate(50px, 20px);
  }
  60% {
    transform: translate(20px, 50px);
  }
  80% {
    transform: translate(-20px, 30px);
  }
  100% {
    transform: translate(0px, 0px);
  }
}
