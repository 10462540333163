/*! CSS Used from: https://html.hixstudio.net/seomy-prv/seomy/assets/css/bootstrap.css */
*,
::after,
::before {
  box-sizing: border-box;
}
p {
  margin-top: 0;
  margin-bottom: 1rem;
}
img {
  vertical-align: middle;
}
.container {
  width: 100%;
  padding-right: var(--bs-gutter-x, 15px);
  padding-left: var(--bs-gutter-x, 15px);
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}
@media (min-width: 1400px) {
  .container {
    max-width: 1200px;
  }
}
.row {
  --bs-gutter-x: 30px;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(var(--bs-gutter-y) * -1);
  margin-right: calc(var(--bs-gutter-x) * -0.5);
  margin-left: calc(var(--bs-gutter-x) * -0.5);
}
.row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}
@media (min-width: 992px) {
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
}
.d-none {
  display: none !important;
}
@media (min-width: 992px) {
  .d-lg-block {
    display: block !important;
  }
}
/*! CSS Used from: https://html.hixstudio.net/seomy-prv/seomy/assets/css/spacing.css */
.pt-100 {
  padding-top: 100px;
}
.pb-120 {
  padding-bottom: 120px;
}
/*! CSS Used from: https://html.hixstudio.net/seomy-prv/seomy/assets/css/main.css */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
p {
  font-family: var(--tp-ff-p);
  font-size: 16px;
  font-weight: 400;
  color: var(--tp-text-body);
  margin-bottom: 15px;
  line-height: 26px;
}
p {
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
::selection {
  background: var(--tp-theme-primary);
  color: var(--tp-common-white);
  text-shadow: none;
}
*::placeholder {
  color: var(--tp-common-black);
  font-size: var(--tp-fz-body);
  opacity: 1;
}
img {
  max-width: 100%;
  height: auto;
}
.p-relative {
  position: relative;
}
.mission-content {
  background: linear-gradient(147.1deg, #fcf0ee 9.5%, #f8c9c1 87.42%);
  border-radius: 6px;
  padding: 60px 70px 48px 70px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mission-content {
    padding: 60px 40px 55px 50px;
  }
}
@media (max-width: 767px) {
  .mission-content {
    padding: 30px 15px 30px 15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px),
  (max-width: 767px) {
  .mission-content {
    margin-bottom: 30px;
  }
}
.mission-content span {
  margin-bottom: 16px;
  display: inline-block;
}
.mission-content p {
  font-family: var(--tp-ff-jakarta);
  font-size: 18px;
  line-height: 28px;
  color: var(--tp-grey-8);
}
.mission-shape-1 {
  position: absolute;
  top: 20px;
  left: -50px;
  animation: updown-three 2.8s linear 0s infinite alternate;
}
.mission-shape-2 {
  position: absolute;
  top: -20px;
  right: -15px;
}
.mission-shape-3 {
  position: absolute;
  top: 110px;
  left: 50px;
}
.mission-two .mission-content {
  margin-left: 48px;
  margin-right: 122px;
  margin-top: -50px;
  background: linear-gradient(160.77deg, #d8ffef 9.28%, #7ddab3 89.34%);
}
@media only screen and (min-width: 768px) and (max-width: 991px),
  (max-width: 767px) {
  .mission-two .mission-content {
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
  }
}
/*! CSS Used keyframes */
@keyframes updown-three {
  0% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(0px);
  }
}


.ceoMsg {
background-color:   linear-gradient(90deg, #100f0f 0%, #444141 43.75%, #eb0808 100%) ;
}