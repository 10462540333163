/*! CSS Used from: https://html.hixstudio.net/seomy-prv/seomy/assets/css/bootstrap.css */
*,
::after,
::before {
  box-sizing: border-box;
}
.dalazu_service h4 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}
.dalazu_service h4 {
  font-size: calc(1.275rem + 0.3vw);
}
@media (min-width: 1200px) {
    .dalazu_service  h4 {
    font-size: 1.5rem;
  }
}
.dalazu_service p {
  margin-top: 0;
  margin-bottom: 1rem;
}
.dalazu_service ul {
  padding-left: 2rem;
}
.dalazu_service ul {
  margin-top: 0;
  margin-bottom: 1rem;
}
.dalazu_service b {
  font-weight: bolder;
}
.dalazu_service img,
svg {
  vertical-align: middle;
}
.dalazu_service .container {
  width: 100%;
  padding-right: var(--bs-gutter-x, 15px);
  padding-left: var(--bs-gutter-x, 15px);
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 576px) {
    .dalazu_service  .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
    .dalazu_service  .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
    .dalazu_service .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
    .dalazu_service .container {
    max-width: 1140px;
  }
}
@media (min-width: 1400px) {
    .dalazu_service .container {
    max-width: 1200px;
  }
}
.dalazu_service .row {
  --bs-gutter-x: 30px;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(var(--bs-gutter-y) * -1);
  margin-right: calc(var(--bs-gutter-x) * -0.5);
  margin-left: calc(var(--bs-gutter-x) * -0.5);
}
.dalazu_service .row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}
@media (min-width: 992px) {
    .dalazu_service .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .dalazu_service .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
}
.dalazu_service .d-flex {
  display: flex !important;
}
.dalazu_service .d-none {
  display: none !important;
}
.dalazu_service .align-self-end {
  align-self: flex-end !important;
}
@media (min-width: 768px) {
    .dalazu_service .d-md-block {
    display: block !important;
  }
}
/*! CSS Used from: https://html.hixstudio.net/seomy-prv/seomy/assets/css/spacing.css */
.dalazu_service .pt-125 {
  padding-top: 125px;
}
.dalazu_service .pb-55 {
  padding-bottom: 55px;
}
.dalazu_service .pl-130 {
  padding-left: 130px;
}
/*! CSS Used from: https://html.hixstudio.net/seomy-prv/seomy/assets/css/main.css */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.dalazu_service h4 {
  font-family: var(--tp-ff-heading);
  color: var(--tp-heading-primary);
  margin-top: 0px;
  font-weight: 700;
  line-height: 1.2;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.dalazu_service h4 {
  font-size: 20px;
}
.dalazu_service ul {
  margin: 0px;
  padding: 0px;
}
.dalazu_service p {
  font-family: var(--tp-ff-p);
  font-size: 16px;
  font-weight: 400;
  color: var(--tp-text-body);
  margin-bottom: 15px;
  line-height: 26px;
}
.dalazu_service p,
li,
h4 {
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
::selection {
  background: var(--tp-theme-primary);
  color: var(--tp-common-white);
  text-shadow: none;
}
*::placeholder {
  color: var(--tp-common-black);
  font-size: var(--tp-fz-body);
  opacity: 1;
}
img {
  max-width: 100%;
  height: auto;
}
.p-relative {
  position: relative;
}
.line-dash-path {
  stroke-dasharray: 4;
  stroke-dashoffset: 350;
  -webkit-animation: tp-line-dash 30s linear forwards infinite;
  animation: tp-line-dash 30s linear forwards infinite;
}
@media (max-width: 767px) {
  .feature-list-4 {
    padding-left: 0;
  }
}
.feature-list-4 li {
  list-style: none;
  margin-bottom: 32px;
}
.feature-list-4 li:last-child {
  margin-bottom: 0;
}
@media (max-width: 767px) {
  .feature-list-4-item {
    padding-left: 0;
  }
}
.feature-list-4-icon {
  flex: 0 0 auto;
  margin-right: 30px;
  position: relative;
}
.feature-list-4-content .title {
  font-family: var(--tp-ff-jakarta);
  font-weight: 600;
  font-size: 22px;
  line-height: 1;
  color: var(--tp-common-11);
  margin-bottom: 20px;
}
.feature-list-4-content p {
  font-family: var(--tp-ff-jakarta);
  font-size: 15px;
  line-height: 22px;
  color: #6f7276;
}
@media only screen and (min-width: 992px) and (max-width: 1199px),
  (max-width: 767px) {
  .feature-list-4-content p br {
    display: none;
  }
}
.feature-list-bg {
  position: relative;
}
.feature-list-bg b {
  position: absolute;
  font-family: var(--tp-ff-jakarta);
  font-weight: 500;
  font-size: 16px;
  line-height: 15px;
  color: var(--tp-common-white);
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  margin: 0 auto;
  text-align: center;
}
.feature-bg-border-1 {
  position: absolute;
  top: 0;
  left: 0;
  border: 1px solid rgba(2, 29, 53, 0.4);
  height: 9px;
  width: 9px;
  border-radius: 50%;
}
.feature-bg-border-2 {
  position: absolute;
  top: 1px;
  left: 12px;
  background: rgba(2, 29, 53, 0.5);
  height: 2px;
  width: 2px;
  border-radius: 50%;
}
.feature-bg-border-3 {
  position: absolute;
  bottom: 6px;
  right: -5px;
  background: rgba(2, 29, 53, 0.5);
  height: 5px;
  width: 5px;
  border-radius: 50%;
}
.feature-bg-border-4 {
  position: absolute;
  bottom: 0;
  right: 0;
  background: rgba(2, 29, 53, 0.5);
  height: 2px;
  width: 2px;
  border-radius: 50%;
}
.feature-4-shape-1 {
  position: absolute;
  right: 0;
  top: 20px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .feature-4-shape-1 {
    right: -20px;
  }
}
.feature-4-shape-2 {
  position: absolute;
  left: -70px;
  top: 8px;
}
.feature-socia-list .feature-bg-border-1 {
  border: 1px solid rgba(66, 96, 255, 0.7);
}
.feature-socia-list .feature-bg-border-2 {
  background: rgba(66, 96, 255, 0.2);
}
.feature-socia-list .feature-bg-border-3 {
  background: rgba(66, 96, 255, 0.2);
}
.feature-socia-list .feature-bg-border-4 {
  background: rgba(66, 96, 255, 0.2);
}
.feature-socia-list.feature-list-4 li {
  margin-bottom: 52px;
}
.feature-socia-list.feature-list-4 li:last-child {
  padding-left: 260px;
}
@media (max-width: 767px) {
  .feature-socia-list.feature-list-4 li:last-child {
    padding-left: 0;
  }
}
.feature-socia-list .feature-4-shape-1 {
  right: 29%;
  transform: rotate(-37deg);
}
.feature-socia-list .feature-4-shape-2 {
  left: 70px;
  top: 100px;
  transform: rotate(-37deg);
}
.feature-inner-gallery {
  padding-bottom: 53px;
  margin-left: -25px;
  position: relative;
}
@media (max-width: 767px) {
  .feature-inner-gallery {
    margin-left: 0;
  }
}
.feature-inner-thumb {
  position: relative;
  z-index: 1;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .feature-inner-thumb {
    text-align: center;
  }
}
.feature-inner-thumb::before {
  position: absolute;
  content: "";
  height: 400px;
  width: 400px;
  background: linear-gradient(
    146.46deg,
    rgba(252, 119, 83, 0.04) 14.32%,
    rgba(252, 119, 83, 0.04) 71.45%
  );
  border-radius: 50%;
  left: 25px;
  bottom: 75px;
  z-index: -1;
}
@media (max-width: 767px) {
  .feature-inner-thumb::before {
    display: none;
  }
}
.feature-inner-shape-1 {
  top: -20px;
  left: -25px;
  position: absolute;
  z-index: 1;
  animation: transform 4s ease-in-out forwards infinite alternate;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .feature-inner-shape-1 {
    top: 0;
    left: 15%;
  }
}
.feature-inner-shape-2 {
  position: absolute;
  top: 5%;
  left: 45%;
  z-index: 1;
  animation: transform 5s ease-in-out forwards infinite alternate;
}
.feature-inner-shape-3 {
  position: absolute;
  top: -15%;
  right: 13%;
  z-index: 1;
  animation: transform 4s ease-in-out forwards infinite alternate;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .feature-inner-shape-3 {
    top: 0;
    right: 25%;
  }
}
.feature-inner-shape-4 {
  position: absolute;
  z-index: 1;
  top: 19%;
  right: 1%;
  animation: transform 5s ease-in-out forwards infinite alternate;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .feature-inner-shape-4 {
    right: 17%;
  }
}
/*! CSS Used keyframes */
@keyframes tp-line-dash {
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes transform {
  0% {
    transform: scale(0.7);
    opacity: 0.8;
  }
  50% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(0.7);
    opacity: 0.8;
  }
}
