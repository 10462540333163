/* a {
  text-decoration: none;
  color: #333;
}

.community {
  background-color: #f7f7f7;
  padding: 60px 0;
}
.community-content {
  display: flex;
  justify-content: center;
  gap: 30px;
  margin-top: 30px;
}
.community-card {
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  padding: 30px;
  text-align: center;
  cursor: pointer;
  transition: transform 0.2s;
}
.community-card:hover {
  transform: translateY(-5px);
}
.community-card .icon {
  font-size: 36px;
  color: #007bff;
}
.community-card h5 {
  font-size: 24px;
  color: #333;
  margin: 0;
}
.section-head {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  font-weight: bold;
  font-size: large;
}

.fab {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
}
.fa-facebook:before {
  content: "\f09a";
}
.fa-facebook-messenger:before {
  content: "\f39f";
}
.fa-instagram:before {
  content: "\f16d";
}
.fa-whatsapp:before {
  content: "\f232";
}
.fab {
  font-family: "Font Awesome 5 Brands";
}
.fab {
  font-weight: 400;
}

@font-face {
  font-family: "Font Awesome 5 Brands";
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/webfonts/fa-brands-400.eot);
  src: url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/webfonts/fa-brands-400.eot?#iefix)
      format("embedded-opentype"),
    url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/webfonts/fa-brands-400.woff2)
      format("woff2"),
    url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/webfonts/fa-brands-400.woff)
      format("woff"),
    url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/webfonts/fa-brands-400.ttf)
      format("truetype"),
    url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/webfonts/fa-brands-400.svg#fontawesome)
      format("svg");
} */

/*! CSS Used from: https://html.hixstudio.net/seomy-prv/seomy/assets/css/bootstrap.css */
*,
::after,
::before {
  box-sizing: border-box;
}
.dservice h2,
.dservice h4 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}
.dservice h2 {
  font-size: calc(1.325rem + 0.9vw);
}
@media (min-width: 1200px) {
  .dservice  h2 {
    font-size: 2rem;
  }
}
.dservice h4 {
  font-size: calc(1.275rem + 0.3vw);
}
@media (min-width: 1200px) {
  .dservice h4 {
    font-size: 1.5rem;
  }
}
.dservice img,
svg {
  vertical-align: middle;
}
.dservice .container {
  width: 100%;
  padding-right: var(--bs-gutter-x, 15px);
  padding-left: var(--bs-gutter-x, 15px);
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 576px) {
  .dservice .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .dservice  .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .dservice .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .dservice  .container {
    max-width: 1140px;
  }
}
@media (min-width: 1400px) {
  .dservice .container {
    max-width: 1200px;
  }
}
.dservice .row {
  --bs-gutter-x: 30px;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(var(--bs-gutter-y) * -1);
  margin-right: calc(var(--bs-gutter-x) * -0.5);
  margin-left: calc(var(--bs-gutter-x) * -0.5);
}
.dservice .row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}
@media (min-width: 576px) {
  .dservice .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }
}
@media (min-width: 992px) {
  .dservice  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .dservice .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }
}
.dservice .d-flex {
  display: flex !important;
}
.dservice .d-none {
  display: none !important;
}
.dservice .justify-content-end {
  justify-content: flex-end !important;
}
.dservice .text-center {
  text-align: center !important;
}
@media (min-width: 768px) {
  .dservice .d-md-block {
    display: block !important;
  }
}
@media (min-width: 992px) {
  .dservice .d-lg-block {
    display: block !important;
  }
}
/*! CSS Used from: https://html.hixstudio.net/seomy-prv/seomy/assets/css/spacing.css */
.mb-15 {
  margin-bottom: 15px;
}
.mb-25 {
  margin-bottom: 25px;
}
.mb-40 {
  margin-bottom: 40px;
}
.mb-70 {
  margin-bottom: 70px;
}
.ml-30 {
  margin-left: 30px;
}
.ml-55 {
  margin-left: 55px;
}
.pt-120 {
  padding-top: 120px;
}
.pb-45 {
  padding-bottom: 45px;
}
.pb-120 {
  padding-bottom: 120px;
}
/*! CSS Used from: https://html.hixstudio.net/seomy-prv/seomy/assets/css/main.css */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
h2,
h4 {
  font-family: var(--tp-ff-heading);
  color: var(--tp-heading-primary);
  margin-top: 0px;
  font-weight: 700;
  line-height: 1.2;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
h2 {
  font-size: 36px;
}
h4 {
  font-size: 20px;
}
h2,
h4 {
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
::selection {
  background: var(--tp-theme-primary);
  color: var(--tp-common-white);
  text-shadow: none;
}
*::placeholder {
  color: var(--tp-common-black);
  font-size: var(--tp-fz-body);
  opacity: 1;
}
.dservice img {
  max-width: 100%;
  height: auto;
}
.p-relative {
  position: relative;
}
.tpsection__title {
  font-weight: 700;
  font-size: 54px;
  line-height: 1.15;
  letter-spacing: -0.02em;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .tpsection__title {
    font-size: 45px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tpsection__title {
    font-size: 30px;
    letter-spacing: 0;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .tpsection__title {
    font-size: 45px;
  }
}
@media (max-width: 767px) {
  .tpsection__title {
    font-size: 30px;
  }
}
.tpbanner__sub-title {
  position: relative;
  display: inline-block;
}
.tpbanner__sub-title span {
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  text-align: center;
  top: 50%;
  transform: translateY(-55%);
  font-weight: 600;
  font-size: 16px;
  color: var(--tp-theme-primary);
  display: inline-block;
}
@media only screen and (min-width: 768px) and (max-width: 991px),
  (max-width: 767px),
  only screen and (min-width: 576px) and (max-width: 767px) {
  .tpprocess__item {
    margin-left: 0;
    margin-right: 0;
    text-align: center;
    justify-content: center !important;
  }
}
.tpprocess__border-bottom {
  border-bottom: 1px solid rgba(12, 19, 56, 0.08);
}
.tpprocess__wrapper {
  text-align: center;
  display: inline-block;
}
.tpprocess__wrapper:hover .tpprocess__count::before {
  transform: translate(-50%, -50%);
}
.tpprocess__count {
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  border-radius: 50%;
  display: inline-block;
  background-color: #3eb9ff;;
  color: #ffffff;
  font-weight: 600;
  font-size: 20px;
  position: relative;
}
.tpprocess__count::before {
  position: absolute;
  content: "";
  border: 1px solid rgba(62, 185, 255, 0.4);
  left: 50%;
  top: 50%;
  transform: translate(-50%, -60%);
  width: 64px;
  height: 64px;
  line-height: 64px;
  text-align: center;
  border-radius: 50%;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.tpprocess__title {
  font-weight: 500;
  font-size: 18px;
  color: var(--tp-grey-3);
  line-height: 1.35;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tpprocess__title {
    font-size: 15px;
  }
}
.tpprocess-shape-one {
  position: absolute;
  top: 10px;
  right: -50px;
}
.tpprocess-shape-two {
  position: absolute;
  top: 10px;
  right: -72px;
}
.tpprocess-shape-three {
  position: absolute;
  top: 10px;
  right: -85px;
}
.tpprocess-shape-four {
  position: absolute;
  top: -300px;
  right: 5px;
  animation: bannerAnimationTwo 8s infinite linear;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tpprocess-shape-four {
    top: -280px;
    right: 30px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .tpprocess-shape-four {
    top: -280px;
    right: 35px;
  }
}
.tpprocess__two .tpprocess__count {
  background-color:#abbf78
}
.tpprocess__two .tpprocess__count::before {
  border: 1px solid rgba(171, 191, 120, 0.4);
}
.tpprocess__three .tpprocess__count {
  background-color: #ffb876
}
.tpprocess__three .tpprocess__count::before {
  border: 1px solid rgba(255, 184, 118, 0.4);
}
.tpprocess__four .tpprocess__count {
  background-color: #ffa0c9
}
.tpprocess__four .tpprocess__count::before {
  border: 1px solid rgba(255, 173, 208, 0.4);
}
.line-dash-path {
  stroke-dasharray: 4;
  stroke-dashoffset: 350;
  -webkit-animation: tp-line-dash 30s linear forwards infinite;
  animation: tp-line-dash 30s linear forwards infinite;
}
/*! CSS Used keyframes */
@keyframes bannerAnimationTwo {
  0% {
    transform: translate(0px, 0px);
  }
  20% {
    transform: translate(20px, -5px);
  }
  40% {
    transform: translate(50px, 20px);
  }
  60% {
    transform: translate(20px, 50px);
  }
  80% {
    transform: translate(-20px, 30px);
  }
  100% {
    transform: translate(0px, 0px);
  }
}
@keyframes tp-line-dash {
  100% {
    stroke-dashoffset: 0;
  }
}
